import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MissionRequest } from '../../service/mission.request';

export interface IMission {
	code: string;
	id: string;
	info?: any;
	loyaltyReward: string;
	loyaltyTokenSymbol: string;
	missionId: string;
	name: string;
	refId?: string | null;
	sourceType?: string | null;
	startAt?: string | null;
	missionGroupName: string | null;
	missionTag: string[];
	status: string;
	type: string;
	userId: string;
	priority: number;
	missionInfo: {
		required_invited?: number;
		icon?: string;
	};
}

export const fetchMissions = createAsyncThunk(
	'mission/fetchMissions',
	async (_, { rejectWithValue }) => {
		try {
			const req = new MissionRequest();
			const res = await req.getMissions();
			return res.data?.data;
		} catch (error: any) {
			console.log('error', error?.response?.data);
			return rejectWithValue(error?.response?.data);
		}
	}
);

interface MissionState {
	missions: IMission[];
}

const initialState = { missions: [] } as MissionState;

const missionSlice = createSlice({
	name: 'mission',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMissions.fulfilled, (state, action) => {
			state.missions = action.payload;
		});
	},
});

export const {} = missionSlice.actions;
export default missionSlice.reducer;
