import { createBrowserRouter } from 'react-router-dom';
import HomeLayout from '../layouts/home.layout';
import HomePage from '../pages/home';
import MissionPage from '../pages/mission';
import PackagesPage from '../pages/packages';
import RankingPage from '../pages/ranking';
import ReferralPage from '../pages/referral';
import WalletPage from '../pages/wallet';
import ProtectedRoute from './protected.route';
import MyRewardsPage from '../pages/my-rewards';
import LuckySpinPage from '../pages/lucky-spin';

export const ROUTES = {
	ERROR: '*',
	HOME: '/',
	PACKAGES: '/packages',
	MISSION: '/mission',
	RANKING: '/ranking',
	REFERRAL: '/referral',
	FRIENDS: '/friends',
	WALLET: '/wallet',
	MY_REWARDS: '/my-rewards',
	LUCKY_SPIN: '/lucky-spin',
};

export const router = createBrowserRouter([
	{
		element: <HomeLayout />,
		children: [
			{
				path: ROUTES.HOME,
				element: (
					<ProtectedRoute>
						<HomePage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.PACKAGES,
				element: (
					<ProtectedRoute>
						<PackagesPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.MISSION,
				element: (
					<ProtectedRoute>
						<MissionPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.RANKING,
				element: (
					<ProtectedRoute>
						<RankingPage />
					</ProtectedRoute>
				),
			},
			// {
			// 	path: ROUTES.FRIENDS,
			// 	element: (
			// 		<ProtectedRoute>
			// 			<FriendsPage />
			// 		</ProtectedRoute>
			// 	),
			// },
			{
				path: ROUTES.REFERRAL,
				element: (
					<ProtectedRoute>
						<ReferralPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.WALLET,
				element: (
					<ProtectedRoute>
						<WalletPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.MY_REWARDS,
				element: (
					<ProtectedRoute>
						<MyRewardsPage />
					</ProtectedRoute>
				),
			},
			{
				path: ROUTES.LUCKY_SPIN,
				element: (
					<ProtectedRoute>
						<LuckySpinPage />
					</ProtectedRoute>
				),
			},
		],
	},
]);
