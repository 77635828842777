import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockUI from '../UI/Block/BlockUI';
import BigNumber from 'bignumber.js';
import { getPackageImage } from '../Packages/Item';
import moment from 'moment';
import { countDownScreenFormat } from '../../utils/format.date';
import { useAppDispatch } from '../../redux/store';
import { fetchMyBoosters } from '../../redux/reducers/booster.slice';

interface IProps {
	boosters: any[];
}

const getHighestBooster = (boosters: any[]) => {
	if (!boosters?.length) return null;
	let highestBooster = boosters[0];
	for (let i = 1; i < boosters?.length; i++) {
		if (
			new BigNumber(boosters[i]?.package?.price).gt(
				highestBooster?.package?.price
			)
		) {
			highestBooster = boosters[i];
		}
	}
	return highestBooster;
};

export default function BoosterCowndown({ boosters }: IProps) {
	const [countdown, setCountdown] = useState('00:00:00:00');
	const highestBooster = getHighestBooster(boosters);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let interval: any;
		if (highestBooster) {
			interval = setInterval(() => {
				const secondsDiff = moment(highestBooster.startAt)
					.add(highestBooster?.package?.validityPeriod, 'seconds')
					.diff(moment(), 'seconds');

				if (secondsDiff <= 0) {
					dispatch(fetchMyBoosters());
					setCountdown(countDownScreenFormat(0, true));
					clearInterval(interval);
				}
				setCountdown(countDownScreenFormat(secondsDiff, true));
			}, 1000);
		}
		return () => {
			clearInterval(interval);
		};
	}, [highestBooster]);

	const totalGrowthRate = boosters.reduce(
		(acc, curr) => acc + curr.growthLoyaltyRate,
		0
	);
	return (
		<Wrapper>
			{!!boosters?.length && (
				<BlockUI
					borderBackground="linear-gradient(to bottom right, #FFFFFF, #FFFFFF41,#FFFFFFBB,#FFFFFF12)"
					bodyBackground="#000"
					wFull
				>
					<BodyWrapper>
						<BoosterInfoWrapper>
							<PackageImg
								src={getPackageImage(highestBooster?.package?.name)}
								alt={highestBooster?.package?.name}
							/>
							<div>
								<BoosterName>Booster</BoosterName>
								<BoosterDescription>
									X{totalGrowthRate} {boosters[0]?.package?.loyaltyTokenSymbol}{' '}
									per hour{' '}
								</BoosterDescription>
							</div>
						</BoosterInfoWrapper>
						<BoosterTime>{countdown}</BoosterTime>
					</BodyWrapper>
				</BlockUI>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	padding: 0 16px;
	height: 68px;
`;
const BodyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
`;

const BoosterInfoWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const BoosterName = styled(Typography)`
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;
const BoosterDescription = styled(Typography)`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
`;
const BoosterTime = styled(Typography)`
	font-family: 'Digital Numbers';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
const PackageImg = styled.img`
	width: 20px;
	margin: 2px 12px;
	/* height: 44px; */
`;
