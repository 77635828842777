import { Typography } from 'antd';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FarmingRequest } from '../../service/farming.request';
import BlockUI from '../UI/Block/BlockUI';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchBalance } from '../../redux/reducers/loyalty-wallet.slice';
import { countDownScreenFormat } from '../../utils/format.date';
import { toast } from 'react-toastify';
import {
	runLoading,
	setFarmPool,
	stopLoading,
} from '../../redux/reducers/farm.slice';
import { formatNumber } from '../../utils/common';

interface Props {
	fetchNotification: () => void;
}

export default function FarmPool({ fetchNotification }: Props) {
	const user = useAppSelector((state: RootState) => state.user);
	const [reward, setReward] = useState('0');
	const [countdown, setCowndown] = useState('00:00:00');
	const dispatch = useAppDispatch();
	const { farmPool } = useAppSelector((state: RootState) => state.farm);
	const [loading, setLoading] = useState(false);
	const [isLoadingFarm, setIsLoadingFarm] = useState(false);

	const fetchCurrentFarmPool = async () => {
		try {
			setIsLoadingFarm(true);
			const farmingReq = new FarmingRequest();
			const res = await farmingReq.getFarmPoolLatest();
			if (res.data?.data) {
				setReward('0');
				dispatch(setFarmPool(res.data?.data));
			}
		} catch (error) {
			// dispatch(setFarmPool(null));
			console.log(error);
		} finally {
			setIsLoadingFarm(false);
		}
	};

	const startFarm = async () => {
		if (loading || isLoadingFarm) return;
		try {
			setLoading(true);
			const farmingReq = new FarmingRequest();
			const res = await farmingReq.createFarmPool();
			if (res.data?.data) {
				dispatch(setFarmPool(res.data?.data));
				dispatch(runLoading());
			}
		} catch (error: any) {
			console.log(error);
			const message = error?.response?.data?.message || '';
			toast.error(message);
		} finally {
			setLoading(false);
		}
	};

	const claim = async () => {
		if (loading || isLoadingFarm) return;
		try {
			setLoading(true);
			const farmingReq = new FarmingRequest();
			if (!farmPool?.id) return;
			const res = await farmingReq.claimFarmPool(farmPool?.id);
			if (res.data?.data) {
				console.log('res.data?.data', res.data?.data);
				setReward('0');
				dispatch(setFarmPool(res.data?.data));
				dispatch(stopLoading());
				dispatch(fetchBalance());
				fetchNotification();
			}
		} catch (error: any) {
			const message = error?.response?.data?.message || '';
			toast.error(message);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!user?.id) return;
		fetchCurrentFarmPool();
	}, [user?.id]);

	useEffect(() => {
		let interval: any;
		if (farmPool && !farmPool.claimAt) {
			dispatch(runLoading());
			interval = setInterval(() => {
				const secondsDiff = moment().diff(farmPool.startAt, 'seconds');
				const remainingSeconds = farmPool.period - secondsDiff;
				if (remainingSeconds > 0) {
					setReward(
						+new BigNumber(farmPool.loyaltyAmount)
							.multipliedBy(secondsDiff)
							.div(farmPool.period)
							.toFixed(0) + ''
					);
					setCowndown(() => {
						return countDownScreenFormat(remainingSeconds);
					});
				} else {
					setReward(farmPool.loyaltyAmount);
					setCowndown('00:00:00');
				}
			}, 1000);
		} else {
			dispatch(stopLoading());
		}
		return () => {
			clearInterval(interval);
		};
	}, [farmPool?.id]);

	const countdownRunning =
		farmPool &&
		moment(farmPool.startAt).add(farmPool.period, 'seconds').isAfter(moment());

	if (!farmPool || farmPool?.claimAt) {
		return (
			<Container>
				<Wrapper>
					<CustomBlock isBtn onClick={startFarm}>
						<StartFarmWrapper>
							<StartFarmTypography>Start Farm</StartFarmTypography>
						</StartFarmWrapper>
					</CustomBlock>
				</Wrapper>
			</Container>
		);
	}
	return (
		<Container>
			<Wrapper>
				<BalanceWrapper>
					<CustomBlock wFull>
						<BalanceBody>
							<Token src="/images/tokens/patc.svg?v=1" alt="patc" />
							<BalanceTypography>
								{formatNumber(reward)} {farmPool.loyaltyTokenSymbol}
							</BalanceTypography>
						</BalanceBody>
					</CustomBlock>
				</BalanceWrapper>
				{countdownRunning ? (
					<CustomBlock>
						<CowndownWrapper>
							<CowndownTime>{countdown}</CowndownTime>
						</CowndownWrapper>
					</CustomBlock>
				) : (
					<BlockUI
						borderBackground="linear-gradient(to right,#5D9FFF,#B8DCFF,#6BBBFF)"
						bodyBackground="#00ABEB"
						isBtn
						onClick={claim}
					>
						<ClaimWrapper>
							<ClaimTypography>Claim</ClaimTypography>
						</ClaimWrapper>
					</BlockUI>
				)}
			</Wrapper>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: flex-end;
	width: 100%;
	flex: 1;
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 0px 16px 40px 16px;
	width: 100%;
`;

const BalanceWrapper = styled.div`
	flex-grow: 1;
`;
const BalanceBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 10px;
`;
const Token = styled.img`
	width: 32px;
`;
const BalanceTypography = styled(Typography)`
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
`;
const CowndownWrapper = styled.div`
	padding: 16px;
`;
const CowndownTime = styled(Typography)`
	font-family: 'Digital Numbers';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const StartFarmWrapper = styled.div`
	padding: 16px 20px;
	width: 290px;
`;
const StartFarmTypography = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
const ClaimWrapper = styled.div`
	padding: 16px;
`;
const ClaimTypography = styled(StartFarmTypography)``;

interface ICustomBlockProps {
	children: ReactNode;
	wFull?: boolean;
	isBtn?: boolean;
	onClick?: () => void;
}
const CustomBlock = ({
	children,
	wFull = false,
	isBtn = false,
	onClick,
}: ICustomBlockProps) => {
	return (
		<BlockUI
			wFull={wFull}
			isBtn={isBtn}
			borderBackground="linear-gradient(to right,#5D9FFF,#B8DCFF,#6BBBFF)"
			bodyBackground="linear-gradient(to right,#3744CE,#10256A)"
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
		>
			{children}
		</BlockUI>
	);
};
