import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';

export const formatNumber = (value: number | string) => {
	const number = new BigNumber(value);
	const formattedNumber = number.toFormat(0);
	return formattedNumber;
};

export const exportFileImage = async (el: HTMLDivElement) => {
	const clonedNode = el.cloneNode(true) as HTMLElement;
	clonedNode.style.width = '100vw';
	clonedNode.style.height = '100vh';
	clonedNode.style.display = 'flex';
	clonedNode.style.flexDirection = 'column';
	clonedNode.style.justifyContent = 'center';
	clonedNode.style.padding = `18px`;

	clonedNode.style.position = 'absolute';
	clonedNode.style.left = '-9999px';
	document.body.appendChild(clonedNode);

	try {
		const canvas = await html2canvas(clonedNode, {
			backgroundColor: null,
			useCORS: true,
		});
		const dataUrl = canvas.toDataURL('image/png');
		console.log(dataUrl);

		const convertToFile = fetch(dataUrl)
			.then((res) => res.blob())
			.then((blob) => {
				const file = new File([blob], `File name`, {
					type: 'image/png',
				});
				return file;
			});
		return convertToFile;
	} catch (error) {
		return null;
	} finally {
		document.body.removeChild(clonedNode);
	}
};

export const nthNumber = (n: number) => {
	const s = ['th', 'st', 'nd', 'rd'];
	const v = n % 100;
	return s[(v - 20) % 10] || s[v] || s[0];
};
