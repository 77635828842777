import React, { useEffect, useState } from 'react';
import Referral from '../components/Referral';
import Friends from '../components/Friends';
import ReferralRequest from '../service/referral.request';
import PopupUI from '../components/UI/Popup/PopupUI';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { fetchReferralProfile } from '../redux/reducers/referral.slice';
import { toast } from 'react-toastify';
import { onCopy } from '../utils/copy';

const ReferralPage = () => {
	const referralRequest = new ReferralRequest();
	const user = useAppSelector((state: RootState) => state.user);
	const { profile } = useAppSelector((state: RootState) => state.referral);
	const [summary, setSummary] = useState<Summary>();
	const [openInvitePopup, setOpenInvitePopup] = useState(false);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);

	const getSummary = async () => {
		try {
			setLoading(true);
			const res = await referralRequest.getSummary();
			setSummary(res.data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleInviteFriends = () => {
		try {
			localStorage.setItem('isFirstInvite', 'true');
			const tg = window.Telegram.WebApp;
			const shareUrl = `https://t.me/share/url?url=https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${profile?.code}`;
			tg.openTelegramLink(shareUrl);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCopyLink = async () => {
		const url = `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${profile?.code}`;
		onCopy(url);
	};

	const fallbackCopyText = (text: string) => {
		const textArea = document.createElement('textarea');
		textArea.value = text;
		// Avoid scrolling to bottom
		textArea.style.position = 'fixed';
		textArea.style.opacity = '0'; // Hide it

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			const successful = document.execCommand('copy');
			toast.success('Copied!');
			console.log('successful', successful);
		} catch (err) {
			console.error(err);
		}

		document.body.removeChild(textArea);
	};

	const onOpenInvitePopup = () => {
		setOpenInvitePopup(true);
	};

	useEffect(() => {
		if (!user?.id) return;
		dispatch(fetchReferralProfile());
		getSummary();
	}, [user?.id]);

	return (
		<>
			{loading ? (
				<LoadingWrapper>
					<img
						src="/images/spinner.gif"
						alt="loading"
						width={'100px'}
						height={'100px'}
					/>
				</LoadingWrapper>
			) : summary?.totalNetwork == '0' ? (
				<Friends onInvite={onOpenInvitePopup} />
			) : (
				<Referral
					summary={summary}
					profile={profile}
					onInvite={onOpenInvitePopup}
				/>
			)}

			<PopupUI
				title="Invite your Friends"
				open={openInvitePopup}
				onClose={() => {
					setOpenInvitePopup(false);
				}}
			>
				<Wrapper>
					<InviteButton>
						<TextButton onClick={handleInviteFriends}>
							Invite Friends Via Telegram
						</TextButton>
					</InviteButton>
					<CopyLinkButton type={'primary'} onClick={handleCopyLink}>
						<TextButton>Copy Link</TextButton>
					</CopyLinkButton>
				</Wrapper>
			</PopupUI>
		</>
	);
};

const LoadingWrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const InviteButton = styled(Button)`
	padding: 12px 20px;
	height: max-content;
`;
const CopyLinkButton = styled(Button)`
	padding: 12px 20px;
	border: 1px solid #00abeb;
	height: max-content;
`;
const TextButton = styled(Typography)`
	color: var(--Main, var(--Colors-white-white, #fff));
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

export default ReferralPage;
