import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Layer from '../components/Home/Layer';
import RefActiveCondition from '../components/Home/RefActiveCondition';
import TelegramLogin from '../components/Home/TelegramLogin';
import NotificationPopup from '../components/Notification/NotificationPopup';
import Tabbar from '../components/UI/Tabbar';
import { fetchBalance } from '../redux/reducers/loyalty-wallet.slice';
import { fetchReferralProfile } from '../redux/reducers/referral.slice';
import { setCurrencies } from '../redux/reducers/wallet.slice';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { ROUTES } from '../routes';
import { NotificationRequest } from '../service/notification.request';
import ReferralRequest from '../service/referral.request';
import walletRequest from '../service/wallet.request';
import LoadingLayout from './loading.layout';
import { fetchUserProfile, setOpenApp } from '../redux/reducers/user.slice';
import { formatNumber } from '../utils/common';
import { LuckySpinRequest } from '../service/lucky-spin.request';

interface INotification {
	userId: string;
	info: any;
	isRead: boolean;
	title: string;
	content: string;
}

export default function HomeLayout() {
	const location = useLocation();
	const navigate = useNavigate();
	const luckySpinRequest = new LuckySpinRequest();
	const isLoading = useAppSelector((state: RootState) => state.user.isLoading);
	const referral = useAppSelector((state: RootState) => state.referral);
	const dispatch = useAppDispatch();
	const onOpenApp = useAppSelector((state: RootState) => state.user.onOpenApp);
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const user = useAppSelector((state: RootState) => state.user);
	const NONE_MENU_PAGE = [
		ROUTES.PACKAGES,
		ROUTES.LUCKY_SPIN,
		ROUTES.MY_REWARDS,
	];

	// const payTelegramLifeExpectancy = async () => {
	// 	try {
	// 		const req = new TelegramRequest();
	// 		const res = await req.payLifeExpectancy();
	// 		fetchNotifications();
	// 		dispatch(fetchBalance());
	// 	} catch (error) {
	// 		console.log('payTelegramLifeExpectancy error', error);
	// 	}
	// };

	const payRewardJoinWithCode = async () => {
		try {
			const req = new ReferralRequest();
			await req.payJoinWithCode();
			localStorage.setItem('payRewardJoinWithCode', 'true');
			fetchNotifications();
			dispatch(fetchBalance());
		} catch (error: any) {
			if (error?.response?.status === 400) {
				localStorage.setItem('payRewardJoinWithCode', 'true');
			}
			console.log('payRewardJoinWithCode error', error);
		}
	};

	const fetchNotifications = async () => {
		try {
			const req = new NotificationRequest();
			const res = await req.getListUnRead();
			if (!res.data?.data) return;
			const data: INotification[] = res.data?.data;
			const newNotifications: INotification[] = [];
			for (let i = 0; i < data?.length; i++) {
				const newNotificationIdx = newNotifications.findIndex(
					(newNoti) => newNoti?.info?.type === data[i]?.info?.type
				);
				if (newNotificationIdx < 0) {
					newNotifications.push(data[i]);
					continue;
				}
				if (
					!newNotifications[newNotificationIdx].info?.reward ||
					!data[i].info?.reward
				) {
					continue;
				}
				newNotifications[newNotificationIdx].info = {
					...newNotifications[newNotificationIdx].info,
					reward: new BigNumber(
						newNotifications[newNotificationIdx].info?.reward
					)
						.plus(data[i]?.info?.reward)
						.toString(),
				};
			}
			setNotifications(
				newNotifications
					?.filter((noti) => noti.info.type !== 'SPIN_REWARD')
					.map((noti) => ({
						...noti,
						content: noti.content.replace(
							'[Z]',
							formatNumber(noti.info.reward) || ''
						),
					}))
			);
		} catch (error) {}
	};

	const getTotalSpin = async () => {
		try {
			const res = await luckySpinRequest.getProfile();
			const totalSpin = res.data.data.totalSpin;
			if (totalSpin > 0) {
				dispatch(setOpenApp(true));
				navigate(ROUTES.LUCKY_SPIN);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getCurrencies = async () => {
		try {
			const res = await walletRequest.getCurrencies();
			dispatch(setCurrencies(res.data.data));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getCurrencies();
	}, []);

	useEffect(() => {
		if (!user?.id) return;
		fetchNotifications();
	}, [user?.id]);

	useEffect(() => {
		const existedRewardJoinWithCode = localStorage.getItem(
			'payRewardJoinWithCode'
		);
		if (referral.profile?.referer && !existedRewardJoinWithCode) {
			payRewardJoinWithCode();
		}
	}, [referral.profile?.referer]);

	useEffect(() => {
		if (
			referral?.profile?.id &&
			location.pathname === ROUTES.HOME &&
			!onOpenApp
		) {
			getTotalSpin();
		}
	}, [referral?.profile?.id, location, onOpenApp]);
	return (
		<>
			{isLoading && <LoadingLayout />}
			<TelegramLogin />
			<div>
				<Wrapper>
					<Layer />
					<Body>{user.id && <Outlet />}</Body>
					{NONE_MENU_PAGE.includes(location.pathname) ? null : <Tabbar />}
					<NotificationPopup
						notifications={notifications}
						reloadNotification={fetchNotifications}
					/>
				</Wrapper>
			</div>

			{/* {referral?.profile?.id && !referral?.profile?.isActive && (
				<div>
					<RefActiveCondition />
				</div>
			)} */}
		</>
	);
}

const Wrapper = styled.div`
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background: #000;
	position: relative;
	box-sizing: border-box;
`;
const Body = styled.div`
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
`;
