import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Balance from '../components/Home/Balance';
import BoosterCowndown from '../components/Home/BoosterCowndown';
import FarmPool from '../components/Home/FarmPool';
import HomeHeader from '../components/Home/Header';
import { fetchMyBoosters } from '../redux/reducers/booster.slice';
import { fetchBalance } from '../redux/reducers/loyalty-wallet.slice';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import Draggable from 'react-draggable';
import { NotificationRequest } from '../service/notification.request';
import CongratulationModal from '../components/LuckySpin/CongratulationModal';
import { ROUTES } from '../routes';
import { useNavigate } from 'react-router-dom';
export default function HomePage() {
	const notificationRequest = new NotificationRequest();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user = useAppSelector((state: RootState) => state.user);
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const { myBoosters } = useAppSelector((state: RootState) => state.booster);
	const [totalNewSpins, setTotalNewSpins] = useState<number>(0);
	const [openNotiNewSpin, setOpenNotiNewSpin] = useState(false);

	useEffect(() => {
		if (!user?.id) return;
		dispatch(fetchMyBoosters());
		dispatch(fetchBalance());
	}, [user?.id]);

	const getNotification = async () => {
		try {
			const res = await notificationRequest.getListUnRead();
			if (!res.data.data) return;
			const notifications: Notification[] = res.data.data?.filter(
				(noti: Notification) => noti.info.type === 'SPIN_REWARD'
			);
			if (notifications.length > 0) {
				setTotalNewSpins(notifications[notifications.length - 1].info.reward);
				setOpenNotiNewSpin(true);
				await notificationRequest.readAll();
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			<Wrapper>
				<HomeHeader />
				<BoosterCowndown boosters={myBoosters} />
				<Balance balance={balance} />
				<FarmPool fetchNotification={getNotification} />
				<Draggable cancel=".cancel">
					<Spinner>
						<SpinImage src="/images/spin/spinner-none-text.svg" />
						<SpinnerText
							onClick={() => navigate(ROUTES.LUCKY_SPIN)}
							className="cancel"
						>
							SPIN
						</SpinnerText>
					</Spinner>
				</Draggable>
			</Wrapper>
			<CongratulationModal
				open={openNotiNewSpin}
				type="turn"
				onClose={() => setOpenNotiNewSpin(false)}
				number={totalNewSpins}
			/>
		</>
	);
}

const Spinner = styled.div`
	position: absolute;
	bottom: 230px;
	left: 20px;
`;

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	box-sizing: border-box;
	position: relative;
	padding-bottom: 110px;
`;

const SpinImage = styled.img`
	width: 100%;
	height: 100%;
`;
const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
`;
const SpinnerText = styled.div`
	color: #fdd95d;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 800;
	line-height: 41px; /* 256.25% */
	letter-spacing: -0.32px;
	animation: ${scaleAnimation} 1s infinite;
	position: absolute;
	top: 28px;
	left: 21px;
	right: 0;
	bottom: 0;
	width: fit-content;
	text-align: center;
	height: fit-content;
`;
