import styled from 'styled-components';
import PageTitle from '../UI/PageTitle';

interface IProps {
	onBack: () => void;
	title: string;
	rightComponent?: React.ReactNode;
}

export default function Header({ onBack, title, rightComponent }: IProps) {
	return (
		<Wrapper>
			<BackBtn onClick={onBack}>
				<img src="/images/packages/back-btn.svg" alt="back-btn" />
			</BackBtn>
			<PageTitle content={title} />
			<div style={{ width: '30px' }}>{rightComponent}</div>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	position: fixed;
	top: 0;
	backdrop-filter: blur(10px);
	padding: 16px;
	box-sizing: border-box;
	z-index: 10;
`;
const BackBtn = styled.div`
	cursor: pointer;
	width: 30px;
	height: 30px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
