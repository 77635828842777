import { Typography } from 'antd';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatNumber } from '../../utils/common';

interface IProps {
	balance: string;
}

export default function Balance({ balance }: IProps) {
	const [balanceState, setBalanceState] = useState('0');
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (count <= 1) {
			setBalanceState(balance);
		} else {
			const interval = setInterval(() => {
				setBalanceState((prevBalance) => {
					const newBalance = new BigNumber(prevBalance).plus(500).toString();
					if (new BigNumber(newBalance).gt(balance)) {
						clearInterval(interval);
						return balance;
					}
					return newBalance;
				});
			}, 10);
			return () => {
				clearInterval(interval);
			};
		}
		setCount((prevCount) => prevCount + 1);
	}, [balance]);

	return (
		<MyBalanceWrapper>
			<Token src="/images/tokens/patc.svg?v=1" alt="patc" />
			<BalanceTypography
			// style={{ minWidth: `${balanceState.length * 22}px` }}
			>
				{balanceState && formatNumber(balanceState)}
			</BalanceTypography>
		</MyBalanceWrapper>
	);
}

const MyBalanceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	margin-top: 5%;
	width: 100%;
`;

const Token = styled.img`
	width: 38px;
	height: 38px;
	object-fit: contain;
`;

const BalanceTypography = styled(Typography)`
	font-family: Poppins;
	font-size: 34px;
	font-weight: 600;
	line-height: 41px; /* 120.588% */
`;
