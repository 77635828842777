import styled from 'styled-components';
import PopupUI from '../UI/Popup/PopupUI';
import { formatCurrency } from '../../utils/copy';

type modalType = 'token' | 'turn';
interface CongratulationModalProps {
	open: boolean;
	onClose: () => void;
	type: modalType;
	number: number;
	tokenImage?: string;
	tokenSymbol?: string;
}
const CongratulationModal = ({
	open,
	onClose,
	type,
	number,
	tokenImage,
	tokenSymbol,
}: CongratulationModalProps) => {
	return (
		<PopupUI open={open} onClose={onClose} title="Congratulation">
			<Wrapper>
				<img src="/images/spin/firework.png" alt="firework" />
				<StyledImage
					src={type === 'token' ? tokenImage : '/images/spin/spinner.svg'}
					alt={type}
				/>
				<Content>
					{type === 'token' ? (
						<>
							<Detail>
								<TokenImage src={tokenImage} alt="token" />
								{formatCurrency(number, 10)} {tokenSymbol}
							</Detail>
							<Description>
								You have won {formatCurrency(number, 10)} {tokenSymbol} from
								Lucky Spin
							</Description>
						</>
					) : (
						<>
							<ReceiveDesc>You have received</ReceiveDesc>
							<TotalTurn>x{number} Spins</TotalTurn>
						</>
					)}
				</Content>
			</Wrapper>
		</PopupUI>
	);
};

export default CongratulationModal;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 15px;
`;
const StyledImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 133px;
	height: 133px;
	object-fit: contain;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;
`;

const Detail = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	color: #f5d245;
	font-size: 22px;
	font-weight: 600;
	line-height: 34px;
`;

const TokenImage = styled.img`
	width: 32px;
	height: 32px;
	object-fit: contain;
`;

const Description = styled.div`
	color: #fff;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
`;

const ReceiveDesc = styled(Description)`
	font-size: 18px;
`;

const TotalTurn = styled(Detail)`
	font-size: 26px;
`;
