import React, { useEffect } from 'react';
import {
	StyledBgInviteFriends,
	StyledBoxBg,
	StyledButtonInviteFriends,
	StyledDescription,
	StyledFooter,
	StyledHeader,
	StyledTitle,
	Wrapper,
} from './index.styled';
import { formatNumber } from '../../utils/common';

interface IProps {
	onInvite: () => void;
}

const Friends = ({ onInvite }: IProps) => {
	useEffect(() => {
		const tg = window.Telegram.WebApp;
		tg.ready();
	}, []);

	return (
		<Wrapper>
			<StyledHeader>
				<StyledTitle>Invite Friends</StyledTitle>
				<StyledDescription>
					Earn 20% on your direct referrals, 10% on their referrals, then 5%,
					2.5% and 1.25% on your fifth level referrals. Plus, you and your
					friend gets {formatNumber(15000)} PATC!
				</StyledDescription>
			</StyledHeader>
			<StyledBoxBg>
				<StyledBgInviteFriends
					src="/images/friends/invite-friend.png"
					alt="invite friend"
				/>
			</StyledBoxBg>

			<StyledFooter>
				<StyledButtonInviteFriends onClick={onInvite}>
					Invite Friends
				</StyledButtonInviteFriends>
			</StyledFooter>
		</Wrapper>
	);
};

export default Friends;
