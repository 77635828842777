import {
	ContentDrawer,
	StyledButtonCancelDisconnect,
	StyledButtonConnectWallet,
	StyledButtonDisconnect,
	StyledButtonDisConnectWallet,
	StyledContainer,
	StyledDescription,
	StyledDrawer,
	StyledFieldAddressWallet,
	StyledFieldConnectWallet,
	StyledFooterDrawer,
	StyledHeader,
	StyledQuestion,
	StyledTitle,
	StyledWalletName,
	Version,
	Wrapper,
} from './index.styled';
import WalletLargeIc from '../Icons/WalletLargeIc';
import { Flex } from 'antd';
import ArrowRightIc from '../Icons/ArrowRightIc';
import {
	useTonAddress,
	useTonConnectUI,
	useTonWallet,
} from '@tonconnect/ui-react';
import CopyOutline from '../Icons/CopyOutline';
import CloseOutlineIc from '../Icons/CloseOutlineIc';
import { useEffect, useState } from 'react';
import CloseCircleBlackIc from '../Icons/CloseCircleBlackIc';
import { UserRequest } from '../../service/user.request';
import { onCopy } from '../../utils/copy';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchUserProfile } from '../../redux/reducers/user.slice';

export const shortenText = (text: string) => {
	return text.slice(0, 3) + '...' + text.slice(-5);
};

const Wallet = () => {
	const dispatch = useAppDispatch();
	const wallet = useTonWallet();
	const userFriendlyAddress = useTonAddress();
	const user = useAppSelector((state) => state.user);
	const loading = useAppSelector((state) => state.user.isLoading);
	const [tonConnectUi] = useTonConnectUI();
	const [open, setOpen] = useState(false);

	const linkWallet = async (walletAddress: string) => {
		try {
			const req = new UserRequest();
			await req.linkWallet(walletAddress);
			dispatch(fetchUserProfile());
		} catch (error) {
			console.log(error);
		}
	};

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (userFriendlyAddress && !loading && !user.walletAddress) {
			linkWallet(userFriendlyAddress);
		}
	}, [userFriendlyAddress, user, loading]);

	return (
		<>
			<Wrapper>
				<div>
					<StyledHeader>
						<StyledTitle>Wallet</StyledTitle>
						<StyledDescription>
							Connect to your TON wallet to receive future rewards!!
						</StyledDescription>
					</StyledHeader>
					<StyledContainer>
						<WalletLargeIc />

						{wallet ? (
							<StyledFieldAddressWallet>
								<StyledWalletName>{wallet.device.appName}</StyledWalletName>
								<Flex gap={16}>
									<StyledButtonConnectWallet
										onClick={() => {
											onCopy(userFriendlyAddress);
										}}
									>
										<Flex gap={12} align="center">
											<div>
												<img
													width={33}
													height={33}
													src="/images/icons/ton.png"
													alt="ton wallet"
												/>
											</div>
											<div>{shortenText(userFriendlyAddress)}</div>
										</Flex>
										<CopyOutline />
									</StyledButtonConnectWallet>
									<StyledButtonDisConnectWallet onClick={() => showDrawer()}>
										<CloseOutlineIc />
									</StyledButtonDisConnectWallet>
								</Flex>
							</StyledFieldAddressWallet>
						) : (
							<StyledFieldConnectWallet>
								<StyledButtonConnectWallet
									onClick={() => tonConnectUi.openModal()}
								>
									<Flex gap={12} align="center">
										<div>
											<img src="/images/icons/ton.png" alt="ton wallet" />
										</div>
										<div>Connect to your TON wallet</div>
									</Flex>
									<ArrowRightIc />
								</StyledButtonConnectWallet>
							</StyledFieldConnectWallet>
						)}
					</StyledContainer>
				</div>
				<Version>Version 2.0.10</Version>
			</Wrapper>
			<StyledDrawer
				title={
					<Flex justify="space-between">
						<div>Wallet</div>
						<CloseCircleBlackIc onClick={() => onClose()} />
					</Flex>
				}
				closable={false}
				onClose={onClose}
				placement="bottom"
				open={open}
				height={'auto'}
			>
				<ContentDrawer>
					<StyledQuestion>
						Are you sure you want to disconnect your TON wallet?
					</StyledQuestion>
					<StyledFooterDrawer>
						<StyledButtonDisconnect
							onClick={() => {
								tonConnectUi.disconnect();
								onClose();
							}}
						>
							Disconnect
						</StyledButtonDisconnect>
						<StyledButtonCancelDisconnect onClick={() => onClose()}>
							Cancel
						</StyledButtonCancelDisconnect>
					</StyledFooterDrawer>
				</ContentDrawer>
			</StyledDrawer>
		</>
	);
};

export default Wallet;
