import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 30px;
	padding-bottom: 110px;
`;
export const Title = styled.div`
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px;
`;
export const Item = styled.div`
	padding: 12px;
	border-radius: 10px;
	border: 1px solid #273662;
	background: #0f2051;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
`;
export const ViewToken = styled.div``;
export const ViewTokenImg = styled.img`
	width: 34px;
	height: 34px;
	object-fit: cover;
`;
export const ViewTokenContent = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`;
export const ViewTokenSymbol = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;
`;
export const ViewTokenName = styled.div`
	text-align: left;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;

export const Withdraw = styled.div`
	display: flex;
	gap: 10px;
	align-items: flex-end;
`;
export const WithdrawButton = styled.div<{ isDisable?: boolean }>`
	border-radius: 4px;
	background: #00abeb;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px; /* 128.571% */
	opacity: ${(props) => (props.isDisable ? 0.5 : 1)};

	display: flex;
	padding: 6px 12px;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;
export const WithdrawValue = styled.div`
	color: #fec424;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px; /* 144.444% */
`;

export const ConfirmButton = styled.div<{ isDisable?: boolean }>`
	width: 100%;
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 10px;
	opacity: ${(props) => (props.isDisable ? 0.5 : 1)};
	background: #00abeb;

	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
export const ContainerPopup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px 16px 0;
`;

export const Balance = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const BalanceToken = styled.img`
	width: 88px;
	height: 62px;
	object-fit: contain;
`;
export const BalanceTitle = styled.div`
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 34px; /* 188.889% */
`;

export const BalanceValue = styled.div`
	color: #f5d245;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 34px; /* 154.545% */
`;

export const WithdrawLabel = styled.div`
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px; /* 128.571% */
`;

export const WithdrawChoose = styled.div`
	width: 100%;
	display: flex;
	gap: 20px;
`;
export const WithdrawChooseButton = styled.div<{
	isActive?: boolean;
	isDisable?: boolean;
}>`
	/* opacity: 0.5; */
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	color: #f5d245;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 34px; /* 212.5% */
	opacity: ${(props) => (props.isDisable ? 0.5 : 1)};
	background: ${(props) => (props.isActive ? '#304afc' : '#082954')};
	padding: 16px;
	width: 100%;
	border-radius: 10px;
`;
export const WithdrawChooseToken = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;
`;

export const WarningText = styled.div`
	color: #eb0000;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;
