import styled from 'styled-components';

export const Wrapper = styled.div`
	color: #fff;
	/* overflow: hidden; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100%;
	box-sizing: border-box;
	padding-bottom: 110px;
`;

export const StyledHeader = styled.div`
	display: flex;
	padding: 16px 0;
	width: calc(100% - 16px);
	flex-direction: column;
	gap: 12px;
	z-index: 1;
`;

export const StyledTitle = styled.div`
	text-align: center;
	font-size: 22px;
	font-weight: 600;
`;

export const StyledDescription = styled.div`
	text-align: center;
	line-height: 26px;
`;

export const StyledBoxBg = styled.div`
	flex: 1;
	width: 100%;
	margin-top: -70px;
	height: auto;
	overflow: hidden;
`;

export const StyledBgInviteFriends = styled.img`
	width: 100%;
	object-fit: cover;
	max-height: 100%;
	z-index: 0;
	object-position: top;
`;

export const StyledFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
`;

export const StyledButtonInviteFriends = styled.button`
	max-width: 290px;
	width: 100%;
	border-radius: 10px;
	padding: 16px;
	color: #fff;
	font-weight: 600;
	border: 1px solid var(--Linear-proposal, #5d9fff);
	background: var(
		--Bg-App,
		radial-gradient(159.85% 51.7% at 51.7% 6.73%, #3744ce 0%, #10256a 100%)
	);
`;
