import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 16px;
	color: #fff;
	height: 100%;
	padding-bottom: 110px;
`;
export const StyledTitle = styled.div`
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	color: #fff;
	margin-bottom: 30px;
`;

export const StyledLayout = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
`;

export const StyledItem = styled.div<{ span?: number }>`
	grid-column: ${(props) => props.span || 1} span;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	border-radius: 14px;
	border: 1px solid var(--Colors-white-white, #fff);
	background: var(
		--Bg-App,
		radial-gradient(159.85% 51.7% at 51.7% 6.73%, #3744ce 0%, #10256a 100%)
	);
`;

export const StyledItemLabel = styled.div`
	font-weight: 600;
	line-height: 26px;
`;
export const StyledItemValue = styled.div<{ fontSize?: string }>`
	display: flex;
	align-items: center;
	gap: 4px;
	color: #f5d245;
	font-weight: 600;
	font-size: ${(props) => props.fontSize || '22px'};
	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
`;

export const StyledErnInvite = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

export const StyledButtonInvite = styled(Button)`
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	padding: 16px 20px;
	height: 53px;
`;
