const InviteFriendIc = () => {
	return (
		<svg
			width={22}
			height={22}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.1591 10.084H10.8368C8.14268 10.084 5.95557 12.2711 5.95557 14.9652V17.4187H16.0403V14.9652C16.0403 12.2668 13.8575 10.084 11.1591 10.084Z"
				fill="white"
			/>
			<path
				d="M11 10.084C12.5188 10.084 13.75 8.85277 13.75 7.33398C13.75 5.8152 12.5188 4.58398 11 4.58398C9.48122 4.58398 8.25 5.8152 8.25 7.33398C8.25 8.85277 9.48122 10.084 11 10.084Z"
				fill="white"
			/>
			<path
				d="M16.5258 10.9988H16.5C17.7676 10.9988 18.7902 9.97187 18.7902 8.70859C18.7902 7.44531 17.7633 6.41836 16.5 6.41836C15.2367 6.41836 14.2098 7.44531 14.2098 8.70859C14.2098 10.5434 15.9887 10.9172 16.6848 12.8938C16.8695 13.4738 16.9383 14.041 16.9598 14.6684V17.4184H20.625V15.098C20.625 12.8336 18.7902 10.9988 16.5258 10.9988ZM5.5 6.41406C4.23242 6.41406 3.20977 7.44102 3.20977 8.7043C3.20977 9.96758 4.23242 10.9988 5.5 10.9988H5.47422C3.20977 10.9988 1.375 12.8336 1.375 15.098V17.4184H5.04023V14.6684C5.05313 14.041 5.12188 13.4652 5.31094 12.8723C6.01133 10.9215 7.79023 10.5434 7.79023 8.70859C7.79023 7.44102 6.76758 6.41406 5.5 6.41406Z"
				fill="white"
			/>
		</svg>
	);
};

export default InviteFriendIc;
