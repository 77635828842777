import { Button, Typography } from 'antd';
import styled from 'styled-components';
import PopupUI from '../UI/Popup/PopupUI';
import { getPackageImage } from './Item';
import { formatNumber } from '../../utils/common';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { NOT_ENOUGH_BALANCE } from '../../constants/error.message';
import { BoosterRequest } from '../../service/booster.request';
import { fetchMyBoosters } from '../../redux/reducers/booster.slice';
import { fetchBalance } from '../../redux/reducers/loyalty-wallet.slice';
import { useState } from 'react';

export interface IPackage {
	id: string;
	description: string;
	name: string;
	price: string;
	growthLoyaltyRate?: number;
	loyaltyBonus?: string;
	loyaltyTokenSymbol: string;
	reducedPeriodPercent: number;
	specialBonusCondition?: string;
	specialLoyaltyBonus?: string;
	validityPeriod: number;
}

interface IProps {
	packageInfo?: IPackage;
	open: boolean;
	onClose: () => void;
}

export default function UpgradeBoosterPopup({
	packageInfo,
	open,
	onClose,
}: IProps) {
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);

	const buyPackage = async (packageId: string, price: string) => {
		if (!packageId || !price) {
			return;
		}
		if (new BigNumber(balance).lt(price)) {
			return toast.error(NOT_ENOUGH_BALANCE);
		}
		try {
			setLoading(true);
			toast.loading('Processing...');
			const boosterReq = new BoosterRequest();
			const res = await boosterReq.buy(packageId);
			toast.dismiss();
			if (res.data?.data) {
				toast.success('Upgrade successful');
				dispatch(fetchMyBoosters());
				dispatch(fetchBalance());
			}
		} catch (error: any) {
			console.log(error);
			toast.dismiss();
			const message = error?.response?.data?.message || '';
			toast.error(message || 'Upgrade failed');
		} finally {
			setLoading(false);
			onClose();
		}
	};
	return (
		<PopupUI
			open={open}
			onClose={onClose}
			footer={
				<BuyButton
					onClick={() => {
						buyPackage(packageInfo?.id || '', packageInfo?.price || '');
					}}
					disabled={!packageInfo || loading}
					loading={loading}
				>
					<TextButton>Buy Now</TextButton>
				</BuyButton>
			}
			title="Booster"
		>
			<ContentWrapper>
				<PackageName>{packageInfo?.name}</PackageName>
				<PackageImgWrapper>
					<PackageImg
						src={getPackageImage(packageInfo?.name || '')}
						alt={packageInfo?.name}
					/>
				</PackageImgWrapper>

				<div>
					<PackageDescription>{packageInfo?.description}</PackageDescription>
					<PriceWrapper>
						<TokenImg src="/images/tokens/patc.svg?v=1" alt="patc" />
						<Price>
							{formatNumber(packageInfo?.price || 0)}{' '}
							{packageInfo?.loyaltyTokenSymbol}
						</Price>
					</PriceWrapper>
				</div>
			</ContentWrapper>
		</PopupUI>
	);
}

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
`;

const BuyButton = styled(Button)`
	height: max-content;
	padding: 12px 20px;
	width: 100%;
`;

const TextButton = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const PackageName = styled(Typography)`
	text-align: center;

	/* App/Title/Semibold */
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
`;

const PackageDescription = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 131.25% */
`;

const PriceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
`;

const Price = styled(Typography)`
	color: #f5d245;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const TokenImg = styled.img`
	width: 32px;
	height: 32px;
`;

const PackageImgWrapper = styled.div`
	display: flex;
	padding: 18.42px 57.895px 15.791px 55.263px;
	justify-content: center;
	align-items: center;
	border-radius: 15.789px;
	border: 2.632px solid #273662;
	background: #000;
`;

const PackageImg = styled.img`
	height: 165.789px;
`;
