import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRequest } from '../../service/user.request';

export const fetchUserProfile = createAsyncThunk(
	'user/fetchProfile',
	async (_, { rejectWithValue }) => {
		try {
			const req = new UserRequest();
			const res = await req.getMe();
			return res.data?.data;
		} catch (error: any) {
			console.log('error', error?.response?.data);
			return rejectWithValue(error?.response?.data);
		}
	}
);

interface UserState {
	id: string;
	username: string;
	telegramId: string;
	accessToken: string;
	avatar?: string;
	onOpenApp?: boolean;
	walletAddress: string;
	isLoading: boolean;
}

const initialState = {
	id: '',
	username: '',
	telegramId: '',
	accessToken: '',
	walletAddress: '',
	onOpenApp: false,
	isLoading: true,
} as UserState;

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUser(state, action: PayloadAction<UserState>) {
			state = {
				...state,
				...action.payload,
			};
			return state;
		},
		setOpenApp(state, action: PayloadAction<boolean>) {
			state.onOpenApp = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
			if (action.payload) {
				return { ...state, ...action.payload, isLoading: false };
			}
		});
	},
});

export const { updateUser, setOpenApp, setLoading } = userSlice.actions;
export default userSlice.reducer;
