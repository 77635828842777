const Diamond = () => {
	return (
		<svg
			width={25}
			height={24}
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="diamond 1" clipPath="url(#clip0_6609_4213)">
				<g id="Group">
					<path
						id="Vector"
						d="M17.7282 9.24188L16.9497 16.0746L12.5095 23.9902L11.0891 13.4107L12.5095 6.86719L17.7282 9.24188Z"
						fill="#1FCBFF"
					/>
					<path
						id="Vector_2"
						d="M24.5001 9.24291C24.5005 9.39792 24.4495 9.55392 24.3455 9.6832L13.0575 23.7289C12.924 23.8947 12.7227 23.9912 12.5095 23.9912L17.7282 9.24291L20.9154 7.95703L24.5001 9.24291Z"
						fill="#22B2FD"
					/>
					<path
						id="Vector_3"
						d="M12.5095 6.8675V23.9905L7.76948 15.2318L7.29126 9.24219L12.5001 6.86328L12.5095 6.8675Z"
						fill="#80DBFF"
					/>
					<path
						id="Vector_4"
						d="M12.5094 23.9912C12.2968 23.9912 12.0954 23.8948 11.9619 23.7289L0.673959 9.68316C0.569991 9.55388 0.518944 9.39792 0.519412 9.24286L4.04371 7.95703L7.29121 9.24291L12.5094 23.9912Z"
						fill="#1FCBFF"
					/>
					<path
						id="Vector_5"
						d="M17.7282 6.08205V9.24269H12.5095L11.0891 6.36433L12.5095 2.92188H15.2167L17.7282 6.08205Z"
						fill="#22B2FD"
					/>
					<path
						id="Vector_6"
						d="M12.5095 2.92188V9.24269H7.2913L6.98877 6.52047L9.80277 2.92188H12.5095Z"
						fill="#1FCBFF"
					/>
					<path
						id="Vector_7"
						d="M24.4999 9.24269H17.7281L15.2167 2.92188H18.1534C18.3393 2.92188 18.5178 2.99542 18.6494 3.12658L24.2934 8.74478C24.4306 8.88109 24.4999 9.06142 24.4999 9.24269Z"
						fill="#1FCBFF"
					/>
					<path
						id="Vector_8"
						d="M9.80268 2.92188L8.16046 7.05906L7.29125 9.24264H0.519409C0.519409 9.06138 0.588737 8.88105 0.72594 8.74473L6.36993 3.12658C6.50155 2.99542 6.68 2.92188 6.86549 2.92188H9.80268Z"
						fill="#80DBFF"
					/>
					<path
						id="Vector_9"
						d="M11.7043 3.62603C11.7043 3.96184 11.4668 4.25041 11.1376 4.31547L11.0313 4.33656C9.93248 4.55341 9.07579 5.40212 8.84863 6.49862C8.78118 6.82506 8.49407 7.05883 8.1606 7.05883L7.25024 3.5343L8.1606 0.00976562C8.49782 0.00976562 8.78728 0.249109 8.85098 0.580234L8.8777 0.719828C9.09173 1.83784 9.9517 2.7025 11.0683 2.92309L11.1381 2.93669C11.4673 3.0017 11.7043 3.29069 11.7043 3.62603Z"
						fill="#FCC100"
					/>
					<path
						id="Vector_10"
						d="M8.16052 0.00976562V7.05888C7.82752 7.05888 7.54037 6.82516 7.47296 6.49867C7.24581 5.40217 6.38912 4.5535 5.29032 4.33661L5.18354 4.31552C4.85429 4.25041 4.61682 3.96189 4.61682 3.62608C4.61682 3.29073 4.85429 3.00175 5.18354 2.93664L5.25334 2.92305C6.36995 2.70245 7.22991 1.8378 7.44395 0.719781L7.47067 0.580188C7.53432 0.249109 7.82377 0.00976562 8.16052 0.00976562Z"
						fill="#FFDB6C"
					/>
					<path
						id="Vector_11"
						d="M7.58749 16.3253C7.58749 16.6611 7.35002 16.9496 7.02077 17.0147L6.91445 17.0358C5.81566 17.2526 4.95897 18.1013 4.73181 19.1978C4.66436 19.5243 4.37725 19.758 4.04378 19.758L3.13342 16.2335L4.04378 12.709C4.381 12.709 4.67045 12.9483 4.73416 13.2795L4.76088 13.419C4.97491 14.5371 5.83488 15.4017 6.95149 15.6223L7.02128 15.6359C7.35049 15.7009 7.58749 15.9899 7.58749 16.3253Z"
						fill="#FCC100"
					/>
					<path
						id="Vector_12"
						d="M4.04375 12.709V19.7581C3.71075 19.7581 3.42359 19.5244 3.35619 19.1979C3.12903 18.1014 2.27234 17.2527 1.17355 17.0358L1.06677 17.0147C0.737469 16.9497 0.5 16.6612 0.5 16.3253C0.5 15.99 0.737469 15.701 1.06672 15.6359L1.13652 15.6223C2.25312 15.4017 3.11309 14.5371 3.32713 13.419L3.35384 13.2795C3.41755 12.9483 3.707 12.709 4.04375 12.709Z"
						fill="#FFDB6C"
					/>
					<path
						id="Vector_13"
						d="M23.5767 17.9073C23.5767 18.2431 23.3393 18.5317 23.01 18.5967L22.9037 18.6178C21.8049 18.8347 20.9482 19.6834 20.7211 20.7799C20.6536 21.1063 20.3665 21.3401 20.033 21.3401L19.1227 17.8155L20.033 14.291C20.3703 14.291 20.6597 14.5304 20.7234 14.8615L20.7501 15.0011C20.9642 16.1191 21.8241 16.9838 22.9407 17.2043L23.0105 17.2179C23.3397 17.283 23.5767 17.5719 23.5767 17.9073Z"
						fill="#FCC100"
					/>
					<path
						id="Vector_14"
						d="M20.0331 14.291V21.3401C19.7001 21.3401 19.4129 21.1064 19.3455 20.7799C19.1184 19.6834 18.2617 18.8347 17.1629 18.6178L17.0561 18.5967C16.7268 18.5316 16.4894 18.2431 16.4894 17.9073C16.4894 17.5719 16.7268 17.283 17.0561 17.2178L17.1259 17.2043C18.2425 16.9837 19.1025 16.119 19.3165 15.001L19.3432 14.8614C19.4069 14.5303 19.6963 14.291 20.0331 14.291Z"
						fill="#FFDB6C"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_6609_4213">
					<rect
						width={24}
						height={24}
						fill="white"
						transform="translate(0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Diamond;
