//@ts-ignore
import { LuckyWheel } from '@lucky-canvas/react';
import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CongratulationModal from './CongratulationModal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { formatCurrency } from '../../utils/copy';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { LuckySpinRequest } from '../../service/lucky-spin.request';
import { NotificationRequest } from '../../service/notification.request';
import { fetchBalance } from '../../redux/reducers/loyalty-wallet.slice';
import { toast } from 'react-toastify';
import { formatNumber } from '../../utils/common';
import Diamond from '../Icons/Diamond';
import Header from '../UI/Header';
import { setOpenApp } from '../../redux/reducers/user.slice';
import GetMoreSpinModal from './GetMoreSpinModal';
// import { SOUND_CONFIG } from '../../constants';

type SoundConfig = 'on' | 'off';
interface SpinResult {
	symbol: string;
	imageUrl: string;
	amount: string;
}

const INITIAL_REWARD: SpinResult = {
	symbol: '',
	imageUrl: '',
	amount: '',
};

const CURRENCY_NUMBER = 10;

const LuckySpin: React.FC = () => {
	const myLucky: any = useRef();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user = useAppSelector((state: RootState) => state.user);
	const [totalSpin, setTotalSpin] = useState(0);
	const notificationRequest = new NotificationRequest();
	const luckySpinRequest = new LuckySpinRequest();
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const [openGetMoreSpinModal, setOpenGetMoreSpinModal] = useState(false);
	const [result, setResult] = useState<SpinResult>(INITIAL_REWARD);
	const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
	const [spins, setSpins] = useState<SpinInfo[]>([]);
	const [newestSpin, setNewestSpin] = useState<SpinInfo>();
	const [config, setConfig] = useState<RewardCongig[]>([]);
	const [totalNewSpins, setTotalNewSpins] = useState<number>(0);
	const [openNotiNewSpin, setOpenNotiNewSpin] = useState(false);
	const [isSpinning, setIsSpinning] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [soundConfig, setSoundConfig] = useState<SoundConfig>('on');
	// const bgAudioRef = useRef<HTMLAudioElement>(null);
	const spinAudioRef = useRef<HTMLAudioElement>(null);
	const [blocks] = useState([
		{
			imgs: [{ src: '/images/spin/spin-circle.png' }],
		},
	]);
	const prizes = isLoading
		? new Array(CURRENCY_NUMBER).fill(0).map((_) => ({
				imgs: [
					{
						top: '27px',
						src: `/images/spin/piece.svg`,
					},
				],
		  }))
		: config.map((token) => ({
				fonts: [
					{
						text: token.symbol,
						top: '67px',
						fontColor: '#fff',
						fontSize: '12px',
						fontWeight: 600,
					},
				],
				imgs: [
					{
						top: '27px',
						src: `/images/spin/piece.svg`,
					},
					{
						top: '35px',
						src: token.imageUrl,
						width: '30px',
						height: '30px',
					},
				],
		  }));
	const [buttons] = useState([
		{
			radius: '30%',
			imgs: [
				{
					src: '/images/spin/go-btn.svg',
					top: '-52px',
					width: '62px',
					height: '85px',
				},
			],
			pointer: true,
		},
	]);

	useEffect(() => {
		if (!user?.id) return;
		getConfig();
		getTotalSpin();
		getLatestSpins();
		getNotification();
	}, [user?.id]);

	useEffect(() => {
		dispatch(setOpenApp(true));
		// const soundConfig = localStorage.getItem(SOUND_CONFIG) || 'on';
		// setSoundConfig('off');
		// localStorage.setItem(SOUND_CONFIG, soundConfig);

		// if (bgAudioRef.current) {
		// 	bgAudioRef.current.currentTime = 0;
		// }
		// if (bgAudioRef.current) {
		// 	bgAudioRef.current.loop = true;
		// }
		// if (spinAudioRef.current) {
		// 	spinAudioRef.current.currentTime = 0;
		// 	spinAudioRef.current.volume = 0;
		// }
		// return () => {
		// bgAudioRef?.current?.pause();
		// spinAudioRef?.current?.pause();
		// };
	}, []);

	useEffect(() => {
		if (spins.length > 0) {
			const getSpinInterval = setInterval(() => {
				//random spin from Spins
				const index = Math.floor(Math.random() * spins.length);
				const spin = spins[index];
				setNewestSpin(spin);
			}, 10000);
			return () => {
				clearInterval(getSpinInterval);
			};
		}
	}, [spins]);
	const getTotalSpin = async () => {
		try {
			const res = await luckySpinRequest.getProfile();
			const totalSpin = res.data.data.totalSpin;
			setTotalSpin(totalSpin);
		} catch (error) {
			console.log(error);
		}
	};
	const getLatestSpins = async () => {
		try {
			const res = await luckySpinRequest.getSpins({
				order: 'createdAt desc',
				page: 1,
				pageSize: 100,
			});
			const spins = res.data.data;
			setSpins(spins);
			if (spins.length > 0) {
				const index = Math.floor(Math.random() * spins.length);
				const spin = spins[index];
				setNewestSpin(spin);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getConfig = async () => {
		try {
			setIsLoading(true);
			const res = await luckySpinRequest.getConfig();
			setConfig(res.data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};
	const getNotification = async () => {
		try {
			const res = await notificationRequest.getListUnRead();
			if (!res.data.data) return;
			const notifications: Notification[] = res.data.data?.filter(
				(noti: Notification) => noti.info.type === 'SPIN_REWARD'
			);
			if (notifications.length > 0) {
				setTotalNewSpins(notifications[notifications.length - 1].info.reward);
				setOpenNotiNewSpin(true);
				await notificationRequest.readAll();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const spin = async () => {
		if (isSpinning) return;
		try {
			setIsSpinning(true);
			const res = await luckySpinRequest.spin();
			const result = res.data.data;
			await getTotalSpin();

			return {
				symbol: result.currencySymbol,
				amount: result.amount,
			};
		} catch (error: any) {
			console.log(error);
			toast.error(error?.response?.data?.message || 'Something went wrong', {
				autoClose: 3000,
			});
		} finally {
			setIsSpinning(false);
		}
	};
	const renderName = (spin: SpinInfo) => {
		const username: string = spin.spinResult?.user?.username;
		return username ? username?.slice(0, 4) + '****' : '****';
	};

	// const renderSpeaker = (config: SoundConfig) => {
	// 	if (config === 'on') {
	// 		return (
	// 			<Speaker
	// 				onClick={handleSoundConfig}
	// 				src="/images/spin/speaker-on.svg"
	// 				alt="speaker-on"
	// 			/>
	// 		);
	// 	}
	// 	return (
	// 		<Speaker
	// 			onClick={handleSoundConfig}
	// 			src="/images/spin/speaker-off.svg"
	// 			alt="speaker-off"
	// 		/>
	// 	);
	// };

	const handleSoundConfig = () => {
		const newConfig = soundConfig === 'on' ? 'off' : 'on';
		if (newConfig === 'on') {
			if (spinAudioRef.current) {
				spinAudioRef.current.volume = 1;
			}
		} else {
			// if (bgAudioRef.current) {
			// 	bgAudioRef.current.volume = 0;
			// }
			if (spinAudioRef.current) {
				spinAudioRef.current.volume = 0;
			}
		}
		setSoundConfig(newConfig);
		// localStorage.setItem(SOUND_CONFIG, newConfig);
	};

	const onCloseCongratulationModal = () => {
		setOpenCongratulationModal(false);
		// spinAudioRef.current?.pause();
		// if (spinAudioRef.current) {
		// 	spinAudioRef.current.currentTime = 0;
		// }
	};
	return (
		<>
			{/* <audio
				style={{
					visibility: 'hidden',
					zIndex: -1,
				}}
				id="spinAudio"
				src="/sounds/spin-win.m4a"
				ref={spinAudioRef}
			></audio> */}
			<Wrapper>
				<Header
					onBack={() => navigate(ROUTES.HOME)}
					title="Lucky Spin"
					// rightComponent={renderSpeaker(soundConfig)}
				/>
				<ContentWrapper>
					<Notification>
						<img src="/images/spin/megaphone.svg" alt="megaphone" />
						{newestSpin && (
							<InfoWrapper>
								<Info>
									<Username>{renderName(newestSpin)}</Username> has won{' '}
									<TokenAmount>
										{formatCurrency(parseFloat(newestSpin.amount))}{' '}
										{newestSpin.currencySymbol}
									</TokenAmount>
								</Info>
							</InfoWrapper>
						)}
					</Notification>
					<MyWallet onClick={() => navigate(ROUTES.MY_REWARDS)}>
						<Diamond />
						My Rewards
					</MyWallet>
					<TotalPatc>
						<TokenImage src="/images/tokens/patc.svg?v=1" alt="patc" />
						{formatCurrency(parseFloat(balance), 6)}
					</TotalPatc>
					<SpinnerWrapper>
						<LuckyWheel
							ref={myLucky}
							width="318px"
							height="318px"
							blocks={blocks}
							prizes={prizes}
							buttons={buttons}
							onStart={async () => {
								if (totalSpin == 0 || isSpinning) {
									if (totalSpin == 0) {
										setOpenGetMoreSpinModal(true);
									}
									return;
								}
								myLucky?.current?.play();
								// spinAudioRef?.current?.play();

								const res = await spin();
								if (!res) {
									setResult(INITIAL_REWARD);
									myLucky?.current?.stop(0);
									// spinAudioRef?.current?.pause();
									// if (spinAudioRef.current) {
									// 	spinAudioRef.current.currentTime = 0;
									// }
									return;
								}
								const index = config.findIndex(
									(item) => item.symbol === res.symbol
								);
								setResult({
									...res,
									imageUrl: config[index]?.imageUrl,
								});

								setTimeout(() => {
									myLucky?.current?.stop(index);
								}, 3500);
							}}
							onEnd={() => {
								if (result.symbol === 'PATC') {
									dispatch(fetchBalance());
								}
								if (parseFloat(result.amount) > 0) {
									setOpenCongratulationModal(true);
								}
							}}
						/>
					</SpinnerWrapper>
					<TotalSpin>
						You have {formatNumber(totalSpin)} Spin{totalSpin > 1 && 's'}
					</TotalSpin>
					<GetMoreSpinBtn onClick={() => setOpenGetMoreSpinModal(true)}>
						<StarIcon src="/images/star.gif" alt="star" /> Get more spin
						<StarIcon src="/images/star.gif" alt="star" />
					</GetMoreSpinBtn>
				</ContentWrapper>
			</Wrapper>
			<CongratulationModal
				open={openCongratulationModal}
				type="token"
				onClose={onCloseCongratulationModal}
				number={parseFloat(result.amount)}
				tokenSymbol={result.symbol}
				tokenImage={result.imageUrl}
			/>
			<CongratulationModal
				open={openNotiNewSpin}
				type="turn"
				onClose={() => setOpenNotiNewSpin(false)}
				number={totalNewSpins}
			/>
			<GetMoreSpinModal
				open={openGetMoreSpinModal}
				setOpen={setOpenGetMoreSpinModal}
			/>
		</>
	);
};

export default LuckySpin;

const GetMoreSpinBtn = styled.button`
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	border-radius: 10px;
	background: #00abeb;
	border: none;

	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	margin: 6px auto;
	width: 100%;
`;

const StarIcon = styled.img`
	width: 26px;
	height: 26px;
	object-fit: contain;
`;
const Wrapper = styled.div`
	height: 100%;
	min-height: 100vh;
	color: #fff;
	background-image: url('/images/spin/bg.png');
	background-size: cover;
	box-sizing: border-box;
	overflow-y: auto;
	padding-bottom: 70px;
`;
const ContentWrapper = styled.div`
	padding: 0 16px;
	margin-top: 70px;
`;
const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 50px;
`;

const Notification = styled.div`
	border-radius: 10px;
	border: 1px solid #fff;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	width: 100%;
	padding: 12px;
	align-items: center;
	gap: 8px;
	box-sizing: border-box;
	font-size: 16px;
	color: #fff;
`;

const Username = styled.span`
	font-weight: 600;
	color: #4da2ff;
`;

const TokenAmount = styled.span`
	font-weight: 600;
	color: #fec424;
`;

const TranslateXAnimation = keyframes`
  0% {
    transform: translateX(calc(100vw - 80px));
  }
  100% {
    transform: translateX(calc(-100vw + 80px));
  }
`;

const InfoWrapper = styled.div`
	max-width: calc(100% - 40px);
	overflow: hidden;
	width: calc(100% - 40px);
`;
const Info = styled.div`
	white-space: nowrap;
	animation: ${TranslateXAnimation} 10s linear infinite;
	font-size: 14px;
	width: 100%;
`;

const MyWallet = styled.div`
	border-radius: 8px;
	margin-top: 16px;
	border: 1px solid #5d9fff;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0.15) 0%,
		rgba(153, 153, 153, 0.15) 100%
	);
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	font-weight: 600;
`;

const TotalSpin = styled.div`
	margin-top: 20px;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px; /* 140% */
`;

const Desc = styled(TotalSpin)`
	margin-top: 0px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
`;

const Speaker = styled.img`
	width: 30px;
	height: 30px;
	object-fit: contain;
`;

const TotalPatc = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 50px;
	margin-top: 50px;

	color: #fff;
	font-size: 34px;
	font-weight: 600;
	line-height: 41px; /* 120.588% */
`;

const TokenImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
