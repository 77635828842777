import React from 'react';
import {
	StyledImageAvatar,
	StyledItemTopRank,
	StyledAccountInfo,
	StyledAccountName,
	WrapperTopRanking,
	StyledTotalFriendTopRank,
} from './index.styled';
import { formatNumber } from '../../utils/common';

const TopRanking = ({ list }: { list: Inviter[] }) => {
	return (
		<WrapperTopRanking>
			<StyledItemTopRank>
				<div>
					<img src="/images/ranking/rank-2.svg" alt="ranking-2" />
				</div>
				<StyledImageAvatar>
					<img src="/images/ranking/avatar.png" alt="avatar" />
				</StyledImageAvatar>
				<StyledAccountInfo>
					<StyledAccountName>{list[1]?.username || '----'}</StyledAccountName>
					<StyledTotalFriendTopRank>
						{list[1]?.totalFriends
							? `${formatNumber(list[1]?.totalFriends)} ${
									list[1]?.totalFriends && parseInt(list[1].totalFriends) > 1
										? 'friends'
										: 'friend'
							  }`
							: '--'}
					</StyledTotalFriendTopRank>
				</StyledAccountInfo>
			</StyledItemTopRank>
			<StyledItemTopRank>
				<div>
					<img src="/images/ranking/rank-1.svg" alt="ranking-2" />
				</div>
				<StyledImageAvatar width="92px">
					<img src="/images/ranking/avatar.png" alt="avatar" />
				</StyledImageAvatar>
				<StyledAccountInfo>
					<StyledAccountName>{list[0]?.username || '--'}</StyledAccountName>
					<StyledTotalFriendTopRank>
						{list[0]?.totalFriends
							? `${formatNumber(list[0]?.totalFriends)} ${
									list[0]?.totalFriends && parseInt(list[0].totalFriends) > 1
										? 'friends'
										: 'friend'
							  }`
							: '--'}
					</StyledTotalFriendTopRank>
				</StyledAccountInfo>
			</StyledItemTopRank>
			<StyledItemTopRank>
				<div>
					<img src="/images/ranking/rank-3.svg" alt="ranking-2" />
				</div>
				<StyledImageAvatar>
					<img src="/images/ranking/avatar.png" alt="avatar" />
				</StyledImageAvatar>
				<StyledAccountInfo>
					<StyledAccountName>{list[2]?.username || '--'}</StyledAccountName>
					<StyledTotalFriendTopRank>
						{list[2]?.totalFriends
							? `${formatNumber(list[2]?.totalFriends)} ${
									list[2]?.totalFriends && parseInt(list[2].totalFriends) > 1
										? 'friends'
										: 'friend'
							  }`
							: '--'}
					</StyledTotalFriendTopRank>
				</StyledAccountInfo>
			</StyledItemTopRank>
		</WrapperTopRanking>
	);
};

export default TopRanking;
