import React, { useEffect, useRef, useState } from 'react';
import {
	Container,
	Desc,
	StyledTextLoading,
	StyledTitlePage,
	Wrapper,
} from './index.styled';
import TopRanking from './TopRanking';
import ListRanking from './ListRanking';
import ReferralRequest from '../../service/referral.request';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const TOP_RANKING = 100;
const Ranking = () => {
	const user = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(true);
	const referralRequest = new ReferralRequest();
	const [inviters, setInviters] = useState<Inviter[]>([]);

	useEffect(() => {
		if (!user?.id) return;
		fetchData();
	}, [user?.id]);

	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await referralRequest.getInviters({
				page: 1,
				pageSize: TOP_RANKING,
			});
			if (!response.data.data?.length) {
				setInviters([]);
				return;
			}
			setInviters(
				response.data.data
					.map((item: any) => {
						return {
							...item,
							username: `${item.username.slice(0, 6)}***`,
						};
					})
					.sort(
						(a: any, b: any) =>
							Number(b.totalFriends || 0) - Number(a.totalFriends || 0)
					)
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Wrapper>
			<Container>
				<StyledTitlePage>Ranking</StyledTitlePage>
				<Desc>based on your network contributions</Desc>
				<TopRanking list={inviters.slice(0, 3)} />
				<ListRanking list={inviters.slice(3, TOP_RANKING)} loading={loading} />
			</Container>
		</Wrapper>
	);
};

export default Ranking;
