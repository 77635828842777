import { useEffect } from 'react';
import { ACCESS_TOKEN } from '../../constants';
import { fetchBalance } from '../../redux/reducers/loyalty-wallet.slice';
import { fetchUserProfile } from '../../redux/reducers/user.slice';
import { useAppDispatch } from '../../redux/store';
import authRequest from '../../service/authRequest';
import { useNavigate } from 'react-router-dom';
import { fetchReferralProfile } from '../../redux/reducers/referral.slice';

enum START_PARAM {
	VIEW = 'v',
}
const TelegramLogin = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const signIn = async (initData: string) => {
		try {
			const res = await authRequest.signIn({
				initData,
				// initData:
				// 'user=%7B%22id%22%3A1833142936%2C%22first_name%22%3A%22Emma%22%2C%22last_name%22%3A%22Dan%22%2C%22username%22%3A%22dandan_275%22%2C%22language_code%22%3A%22vi%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1726656766&hash=de65537213e3d26955e69510490ebb7ee671e4a9b57aeb683063ced60b6c0bb2',
			});
			localStorage.setItem(ACCESS_TOKEN, res.data.data.accessToken);
			dispatch(fetchUserProfile());
			dispatch(fetchBalance());
			dispatch(fetchReferralProfile());
		} catch (error) {
			console.log(error);
		}
	};

	const handleRedirect = (param: string | undefined) => {
		if (!param) return;
		const paramList = param.split('-');
		paramList.forEach((param) => {
			const params = param.split('_');
			if (params[0] === START_PARAM.VIEW) {
				navigate(`/${params[1]}`);
			}
		});
	};
	useEffect(() => {
		const tg = window.Telegram.WebApp;
		signIn(tg.initData);
		handleRedirect(tg.initDataUnsafe.start_param);
	}, []);

	return <></>;
};

export default TelegramLogin;
