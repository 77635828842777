import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WalletState {
	wallets: WalletResponse[];
	currencies: Currency[];
	isLoadingWallet: boolean;
}

const initialState = {
	wallets: [],
	currencies: [],
	isLoadingWallet: true,
} as WalletState;

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWallets(state, action: PayloadAction<any>) {
			state.wallets = action.payload;
			return state;
		},
		setCurrencies(state, action: PayloadAction<any>) {
			state.currencies = action.payload;
			return state;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoadingWallet = action.payload;
			return state;
		},
	},
});

export const { setWallets, setCurrencies, setLoading } = walletSlice.actions;
export default walletSlice.reducer;
