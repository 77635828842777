import styled from 'styled-components';

export const Wrapper = styled.div`
	/* padding: 16px; */
	padding-bottom: 110px;
`;

export const Container = styled.div`
	padding: 16px;
`;

export const StyledTitlePage = styled.div`
	font-size: 22px;
	line-height: 28px;
	font-weight: 600;
	text-align: center;
	color: #fff;
`;

export const Desc = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	margin-top: 2px;
`;

// Top Ranking
export const WrapperTopRanking = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
	height: 217px;
	margin-top: 20px;
`;
export const StyledItemTopRank = styled.div`
	position: relative;
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
`;
export const StyledImageMedal = styled.div``;
export const StyledImageAvatar = styled.div<{ width?: string }>`
	img {
		width: ${(props) => props.width || '72px'};
	}
`;
export const StyledAccountInfo = styled.div`
	color: white;
	text-align: center;
`;
export const StyledAccountName = styled.div`
	font-weight: 600;
	line-height: 24px;
`;
export const StyledTotalFriendTopRank = styled.div`
	font-size: 14px;
	line-height: 18px;
`;

// List Ranking
export const StyledWrapperListRanking = styled.div`
	margin-top: 30px;
	padding-left: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
export const StyledItemRanking = styled.div`
	background: #0f2051;
	border: 1px solid #273662;
	border-radius: 10px;
	color: white;
	display: flex;
	gap: 12px;
	align-items: center;
	padding: 12px 12px 12px 0;
`;
export const StyledCircleRanking = styled.div`
	background: #00abeb;
	border: 1px solid #273662;
	padding: 10px;
	border-radius: 999px;
	margin-left: calc(-47px / 2);
	font-weight: 700;
	width: 46px;
	height: 46px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 46px;
	color: #fff;
`;
export const StyledAccountRanking = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const StyledTextLoading = styled.div`
	color: #fff;
	padding: 16px 0;
	font-weight: 600;
`;
