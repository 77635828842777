import styled from 'styled-components';
import PopupUI from '../UI/Popup/PopupUI';
import CalendarIc from '../Icons/CalendarIc';
import InviteFriendIc from '../Icons/InviteFriendIc';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect } from 'react';
import { fetchMissions, IMission } from '../../redux/reducers/mission.slice';
import { MISSION_CODE, MISSION_STATUS } from '../../constants/mission';
import { SUPPORTED_PLATFORM } from '../../constants';
import { toast } from 'react-toastify';
import { MissionRequest } from '../../service/mission.request';

interface GetMoreSpinModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const GetMoreSpinModal: React.FC<GetMoreSpinModalProps> = ({
	open,
	setOpen,
}: GetMoreSpinModalProps) => {
	const referral = useAppSelector((state: RootState) => state.referral);
	const user = useAppSelector((state: RootState) => state.user);
	const { missions } = useAppSelector((state: RootState) => state.mission);
	const dispatch = useAppDispatch();
	const dailySharingTask = missions.find(
		(mission: IMission) => mission.code === MISSION_CODE.DAILY_SHARING_STORY
	);

	useEffect(() => {
		if (!user?.id) return;
		dispatch(fetchMissions());
	}, [user?.id]);

	const handleInviteFriends = () => {
		try {
			const tg = window.Telegram.WebApp;
			const shareUrl = `https://t.me/share/url?url=https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${referral?.profile?.code}`;
			tg.openTelegramLink(shareUrl);
		} catch (error) {
			console.error(error);
		}
	};

	const shareStory = async () => {
		if (!dailySharingTask) {
			toast.error('Mission not found');
			return;
		}
		if (!SUPPORTED_PLATFORM.includes(window?.Telegram?.WebApp?.platform)) {
			toast.error('Please use your phone to perform the task');
			return;
		}
		try {
			if (!(window.Telegram && window.Telegram.WebApp)) {
				toast.error('Telegram not found');
				return;
			}
			const tg = window.Telegram.WebApp;
			tg.shareToStory(dailySharingTask?.info.url, {
				text: `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${referral.profile?.code} #PATC #PADTON #PADTONCommunity`,
				widget_link: {
					name: 'link',
					url: `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${referral.profile?.code}`,
				},
			});
			const req = new MissionRequest();
			await req.claim(dailySharingTask.id);
			dispatch(fetchMissions());
		} catch (error: any) {
			console.log(error);
			const message = error?.response?.data?.message || error;
			toast.error(message);
		}
	};

	return (
		<PopupUI
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			closeable={true}
			title="Get More Spins"
		>
			<Desc>Complete the following tasks to get more spins:</Desc>
			<Tasks>
				<Task>
					<LeftBlock>
						<IconWrapper>
							<InviteFriendIc />
						</IconWrapper>
						<TaskInfo>
							<TaskName>
								Invite Friends
								<FireIcon src="/images/fire.gif" alt="fire" />{' '}
							</TaskName>
							<TaskDesc>Get 10 spins for each successful invite</TaskDesc>
						</TaskInfo>
					</LeftBlock>
					<GoBtn onClick={handleInviteFriends}>Invite</GoBtn>
				</Task>
				<Divider />
				<Task>
					<LeftBlock>
						<IconWrapper>
							<CalendarIc />
						</IconWrapper>
						<TaskInfo>
							<TaskName>
								Daily Sharing Story{' '}
								<FireIcon src="/images/fire.gif" alt="fire" />{' '}
							</TaskName>
							<TaskDesc>
								Get 10 spins after completing the daily sharing story mission
							</TaskDesc>
						</TaskInfo>
					</LeftBlock>
					<GoBtn
						disabled={
							!user?.id ||
							!dailySharingTask ||
							dailySharingTask?.status === MISSION_STATUS.SUCCESS
						}
						onClick={shareStory}
					>
						Share
					</GoBtn>
				</Task>
			</Tasks>
		</PopupUI>
	);
};

export default GetMoreSpinModal;

const GoBtn = styled.button`
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 6px;
	background: #00abeb;
	width: 80px;

	color: var(--Main, var(--Colors-white-white, #fff));
	text-align: center;

	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
	border: none;
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
`;

const Desc = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px; /* 162.5% */
`;

const Tasks = styled.div`
	border-radius: 12px;
	background: #0f2051;
	display: flex;
	width: 100%;
	padding: 12px;
	flex-direction: column;
	box-sizing: border-box;
	gap: 12px;
`;

const Task = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(255, 255, 255, 0.1);
`;
const IconWrapper = styled.div`
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(
		224deg,
		rgba(255, 255, 255, 0.2) 1.8%,
		rgba(255, 255, 255, 0) 100%
	);
	display: flex;
	width: 44px;
	height: 44px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
`;

const LeftBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const TaskInfo = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const TaskName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	display: flex;
	align-items: center;
`;

const TaskDesc = styled.div`
	color: #fff;
	text-align: left;
	font-family: Poppins;
	font-size: 11px;
	font-weight: 400;
	line-height: 13px;
`;
const FireIcon = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;
