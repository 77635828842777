import { Typography } from 'antd';
import styled from 'styled-components';
import BlockUI from '../UI/Block/BlockUI';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { RootState, useAppSelector } from '../../redux/store';

export default function HomeHeader() {
	const navigate = useNavigate();
	const user = useAppSelector((state: RootState) => state.user);
	return (
		<Wrapper>
			<ProfileWrapper>
				<Avatar
					src={user.avatar || '/images/ranking/avatar.png'}
					alt="avatar"
				/>
				<NameTypography>{user.username}</NameTypography>
			</ProfileWrapper>
			<BlockUI
				borderBackground="linear-gradient(to right, #5d9fff, #b8dcff, #6bbbff)"
				bodyBackground="#446FCD"
				isBtn
				onClick={() => {
					navigate(ROUTES.PACKAGES);
				}}
			>
				<BoosterWrapper>
					<img src={'/images/icons/rocket-ic.png'} alt="rocket-ic" />
					<NameTypography>Booster</NameTypography>
				</BoosterWrapper>
			</BlockUI>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px 22px;
`;
const BoosterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 10px 20px;
	img {
		width: 21px;
		height: 21px;
	}
`;
const NameTypography = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
	flex: 1;
`;

const ProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Avatar = styled.img`
	border-radius: 100%;
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
