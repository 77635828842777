import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FriendActiveIc from '../../Icons/FriendActiveIc';
import FriendIc from '../../Icons/FriendIc';
import HomeActiveIc from '../../Icons/HomeActiveIc';
import HomeIc from '../../Icons/HomeIc';
import MissionActiveIc from '../../Icons/MissionActiveIc';
import MissionIc from '../../Icons/MissionIc';
import RankingActiveIc from '../../Icons/RankingActiveIc';
import RankingIc from '../../Icons/RankingIc';
import WalletActiveIc from '../../Icons/WalletActiveIc';
import WalletIc from '../../Icons/WalletIc';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';

interface Tab {
	key: number;
	bg: string;
	name: string;
	route: string;
	icon: JSX.Element;
	activeIcon: JSX.Element;
}

export default function Tabbar() {
	const location = useLocation();
	const tabs = [
		{
			key: 1,
			bg: '/images/home/tab-bg-1.png',
			name: 'Mission',
			route: ROUTES.MISSION,
			icon: <MissionIc />,
			activeIcon: <MissionActiveIc />,
		},
		{
			key: 2,
			bg: '/images/home/tab-bg-2.png',
			name: 'Friends',
			route: ROUTES.REFERRAL,
			icon: <FriendIc />,
			activeIcon: <FriendActiveIc />,
		},
		{
			key: 3,
			bg: '/images/home/tab-bg-3.png',
			name: 'Home',
			route: ROUTES.HOME,
			icon: <HomeIc />,
			activeIcon: <HomeActiveIc />,
		},
		{
			key: 4,
			bg: '/images/home/tab-bg-4.png',
			name: 'Ranking',
			route: ROUTES.RANKING,
			icon: <RankingIc />,
			activeIcon: <RankingActiveIc />,
		},

		{
			key: 5,
			bg: '/images/home/tab-bg-5.png',
			name: 'Wallet',
			route: ROUTES.WALLET,
			icon: <WalletIc />,
			activeIcon: <WalletActiveIc />,
		},
	] as Tab[];
	const [tabKey, setTabKey] = useState<number>(1);
	const navigate = useNavigate();

	const onSelectTab = (tab: Tab) => {
		setTabKey(tab.key);
		navigate(tab.route);
	};

	useEffect(() => {
		setTabKey(tabs.find((tab) => tab.route === location.pathname)?.key || 3);
	}, [location.pathname]);

	return (
		<Wrapper>
			<TabItemActiveWarpper leftIndex={tabKey - 1}>
				<TabItemActive>
					<InsideTabItemActive>
						<TabItemIconActive>
							{tabs.find((tab) => tab.key === tabKey)?.activeIcon}
						</TabItemIconActive>
					</InsideTabItemActive>
				</TabItemActive>
			</TabItemActiveWarpper>
			<Background bg={tabs.find((tab) => tab.key === tabKey)?.bg || ''}>
				{tabs.map((tab) => {
					return (
						<TabItem key={tab.key} onClick={() => onSelectTab(tab)}>
							<TabItemIcon show={tab.key !== tabKey}>{tab.icon}</TabItemIcon>
							<TabItemContent bold={tab.key === tabKey}>
								{tab.name}
							</TabItemContent>
						</TabItem>
					);
				})}
			</Background>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 2;
`;

const TabItemActiveWarpper = styled.div<{ leftIndex: number }>`
	width: 20%;
	position: absolute;
	top: -26px;
	display: flex;
	justify-content: center;
	transition: transform 0.5s;
	transform: ${(props) => `translateX(${props.leftIndex * 100}%)`};
`;

const TabItemActive = styled.div`
	border-radius: 100%;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(5px);
	padding: 5px;
	/* border: 5px solid rgba(255, 255, 255, 0.1); */
`;

const InsideTabItemActive = styled.div`
	background-color: #0690e6;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
`;

const TabItemIconActive = styled.div``;

const Background = styled.div<{ bg: string }>`
	background-image: ${(props) => `url(${props.bg})`};
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/* height: 100px; */
	width: 100%;
	transition: background-image 0.2s;
	display: flex;
	justify-content: space-between;
`;
const TabItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	padding-top: 14px;
	padding-bottom: 22px;
	width: 20%;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
`;

const TabItemIcon = styled.div<{ show: boolean }>`
	visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

const TabItemContent = styled(Typography)<{ bold: boolean }>`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: ${(props) => (props.bold ? 700 : 400)};
	line-height: 16px;
`;
