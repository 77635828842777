import { Skeleton } from 'antd';
import { formatNumber } from '../../utils/common';
import {
	StyledAccountName,
	StyledAccountRanking,
	StyledCircleRanking,
	StyledItemRanking,
	StyledWrapperListRanking,
} from './index.styled';
import { RootState, useAppSelector } from '../../redux/store';
import { useEffect } from 'react';

const ListRanking = ({
	list,
	loading,
}: {
	list: Inviter[];
	loading: boolean;
}) => {
	const user = useAppSelector((state: RootState) => state.user);

	useEffect(() => {
		if (list.length > 0 && user?.id) {
			const userEl = document.getElementById('user-ranking');
			if (userEl) {
				userEl.setAttribute(
					'style',
					'position: sticky; bottom: 120px; background-color: #05B2EC; border: 1px solid #05B2EC; color: #0F2051; '
				);
			}
		}
	}, [list, user?.id]);
	return (
		<StyledWrapperListRanking>
			{loading
				? new Array(6).fill(0).map((_, index: number) => (
						<StyledItemRanking key={index}>
							<StyledCircleRanking />
							<StyledAccountRanking>
								<StyledAccountName>
									<Skeleton.Input
										style={{
											backgroundColor: 'rgba(255,255,255,0.1)',
											height: '26px',
											borderRadius: '10px',
										}}
									/>
								</StyledAccountName>
								<div>
									<Skeleton.Input
										style={{
											backgroundColor: 'rgba(255,255,255,0.1)',
											minWidth: '70px',
											width: '70px',
											height: '26px',
											borderRadius: '10px',
										}}
									/>
								</div>
							</StyledAccountRanking>
						</StyledItemRanking>
				  ))
				: list.map((item, index) => (
						<StyledItemRanking
							id={item.userId === user?.id ? 'user-ranking' : ''}
							key={item.id}
						>
							<StyledCircleRanking>{index + 4}</StyledCircleRanking>
							<StyledAccountRanking>
								<StyledAccountName>{item.username}</StyledAccountName>
								<div>
									{formatNumber(item.totalFriends)}{' '}
									{parseInt(item.totalFriends) > 1 ? 'Friends' : 'Friend'}
								</div>
							</StyledAccountRanking>
						</StyledItemRanking>
				  ))}
		</StyledWrapperListRanking>
	);
};

export default ListRanking;
