import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import DiamondIc from '../Icons/DiamondIc';
import { ISummary } from '../../pages/mission';
import { formatNumber } from '../../utils/common';

interface IProps {
	summary: ISummary | null;
	loading: boolean;
}

export default function MissionSummary({ summary, loading }: IProps) {
	return (
		<Wrapper>
			<Block>
				<BlockTitle>Completed Mission</BlockTitle>
				<BlockContentWrapper>
					<DiamondIc />
					<BlockText>{formatNumber(summary?.completedMission || 0)}</BlockText>
				</BlockContentWrapper>
			</Block>
			<Block>
				<BlockTitle>{summary?.loyaltySymbol || 'PATC'} Received</BlockTitle>
				<BlockContentWrapper isRight>
					<TokenImg src="/images/tokens/patc.svg?v=1" alt="patc" />
					<BlockText>
						{loading
							? '------'
							: summary?.loyaltyReceived &&
							  formatNumber(summary.loyaltyReceived)}
					</BlockText>
				</BlockContentWrapper>
			</Block>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	margin-top: 30px;
	padding: 20px;
	border-radius: 14px;
	border: 1px solid #a7d9fc;
	background: radial-gradient(
		159.85% 51.7% at 51.7% 6.73%,
		#3744ce 0%,
		#10256a 100%
	);
	display: flex;
	justify-content: space-between;
`;

const Block = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const BlockTitle = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const BlockContentWrapper = styled.div<{ isRight?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.isRight ? 'flex-end' : 'flex-start')};
	gap: 4px;
`;
const BlockText = styled(Typography)`
	color: var(--Web-Yellow, #ffe500);
	font-family: Poppins;
	font-size: 22px;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
`;
const TokenImg = styled.img`
	width: 24px;
`;
