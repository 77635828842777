import styled from 'styled-components';
import { MISSION_CODE, MISSION_STATUS } from '../../constants/mission';
import { Button, Typography } from 'antd';
import ConnectWalletIc from '../Icons/ConnectWalletIc';
import TelegramIc from '../Icons/TelegramIc';
import XIc from '../Icons/XIc';
import CoinMarketCapIc from '../Icons/CoinMarketCapIc';
import BinanceIc from '../Icons/BinanceIc';
import WebsiteIc from '../Icons/WebsiteIc';
import HackathonIc from '../Icons/HackathonIc';
import MascotIc from '../Icons/MascotIc';
import InviterIc from '../Icons/Inviter';
import CalendarIc from '../Icons/CalendarIc';
import { RootState, useAppSelector } from '../../redux/store';
import { formatNumber } from '../../utils/common';
import { IMission } from '../../redux/reducers/mission.slice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const getMissionIcon = (mission: IMission) => {
	switch (mission.code) {
		case MISSION_CODE.DAILY_SHARING_STORY: {
			return <CalendarIc />;
		}
		case MISSION_CODE.CONNECT_WALLET: {
			return <ConnectWalletIc />;
		}
		case MISSION_CODE.JOIN_TELE_CHANNEL:
		case MISSION_CODE.JOIN_TELE_COMMUNITY: {
			return <TelegramIc />;
		}
		case MISSION_CODE.FOLLOW_X: {
			return <XIc />;
		}
		case MISSION_CODE.FOLLOW_COINMARKETCAP_COMMUNITY: {
			return <CoinMarketCapIc />;
		}
		case MISSION_CODE.FOLLOW_BINANCE_SQUARE: {
			return <BinanceIc />;
		}
		case MISSION_CODE.VISIT_WEBSITE: {
			return <WebsiteIc />;
		}
		case MISSION_CODE.VISIT_HACKATHON_EVENT: {
			return <HackathonIc />;
		}
		case MISSION_CODE.VISIT_MASCOT_CONTEST: {
			return <MascotIc />;
		}
		case MISSION_CODE.INVITE_1_FRIEND:
		case MISSION_CODE.INVITE_3_FRIENDS:
		case MISSION_CODE.INVITE_10_FRIENDS:
		case MISSION_CODE.INVITE_100_FRIENDS:
		case MISSION_CODE.INVITE_1000_FRIENDS:
		case MISSION_CODE.TOP_1000_INVITERS_WEEK: {
			return <InviterIc />;
		}
		case MISSION_CODE.TOP_1_EARNER_WEEK:
		case MISSION_CODE.TOP_3_EARNERS_WEEK:
		case MISSION_CODE.TOP_10_EARNERS_WEEK:
		case MISSION_CODE.TOP_100_EARNERS_WEEK:
		case MISSION_CODE.TOP_1000_EARNERS_WEEK: {
			return <InviterIc />;
		}
		default:
			return null;
	}
};

const INVITE_MISSIONS = [
	MISSION_CODE.INVITE_1_FRIEND,
	MISSION_CODE.INVITE_3_FRIENDS,
	MISSION_CODE.INVITE_10_FRIENDS,
	MISSION_CODE.INVITE_100_FRIENDS,
	MISSION_CODE.INVITE_1000_FRIENDS,
];
interface IProps {
	task: IMission;
	claimBtnText?: string;
	claimReward: (task: IMission) => void;
	startMission?: (id: string) => void;
	totalInvited?: number;
	loading?: boolean;
}

export default function TaskItemComponent({
	task,
	claimBtnText,
	claimReward,
	startMission,
	totalInvited,
	loading,
}: IProps) {
	const navigate = useNavigate();
	const user = useAppSelector((state: RootState) => state.user);

	const handleClaimInviteTask = async (task: IMission) => {
		if (!startMission) {
			return;
		}
		await startMission(task.id);
		await claimReward(task);
	};

	return (
		<Card key={task?.id}>
			<TaskInfoWrapper>
				<TaskIconWrapper>
					{task.missionInfo.icon ? (
						<img src={task.missionInfo.icon} alt={task.name} />
					) : (
						<TelegramIc />
					)}
				</TaskIconWrapper>
				<TaskContentWrapper>
					<TaskName>{task?.name}</TaskName>
					<BonusWrapper>
						<Reward>
							{task?.loyaltyReward && formatNumber(task.loyaltyReward)}
						</Reward>
						<TokenImage src="/images/tokens/patc.svg?v=1" alt="patc" />
					</BonusWrapper>
				</TaskContentWrapper>
			</TaskInfoWrapper>
			{task?.status === MISSION_STATUS.SUCCESS ? (
				<img src="/images/mission/task-done.png" alt="task-done" />
			) : INVITE_MISSIONS.includes(task.code as MISSION_CODE) &&
			  task.missionInfo?.required_invited ? (
				<>
					{!!totalInvited &&
					totalInvited >= task.missionInfo.required_invited ? (
						<StartButton
							onClick={() => handleClaimInviteTask(task)}
							loading={loading}
						>
							Claim
						</StartButton>
					) : (
						<StartButton
							onClick={() => navigate(ROUTES.REFERRAL)}
							loading={loading}
						>
							Start
						</StartButton>
					)}
				</>
			) : task?.status === MISSION_STATUS.PENDING ? (
				<StartButton
					onClick={() => {
						try {
							if (task.code === MISSION_CODE.CONNECT_WALLET) {
								if (!user.walletAddress) {
									return navigate(ROUTES.WALLET);
								}
							}

							claimReward(task);
						} catch (error) {
							console.log(error);
						}
					}}
					loading={loading}
				>
					{task.code === MISSION_CODE.CONNECT_WALLET && !user.walletAddress
						? 'Start'
						: claimBtnText || 'Claim'}
				</StartButton>
			) : (
				<StartButton
					onClick={() => {
						if (startMission) {
							startMission(task?.id);
						}
					}}
					loading={loading}
				>
					Start
				</StartButton>
			)}
		</Card>
	);
}

const Card = styled.div`
	padding: 12px;
	border-radius: 10px;
	border: 1px solid #273662;
	background: #0f2051;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	&:hover {
		border: 1px solid #fff;
	}
`;
const TaskInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	flex: 1;
`;
const TaskIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(
		224deg,
		rgba(255, 255, 255, 0.2) 1.8%,
		rgba(255, 255, 255, 0) 100%
	);
`;
const TaskContentWrapper = styled.div`
	flex-shrink: 10;
	flex: 1;
`;
const TaskName = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 162.5% */
`;
const BonusWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
`;
const TokenImage = styled.img`
	width: 20px;
`;
const Reward = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;
const StartButton = styled(Button)`
	height: max-content;
	padding: 8px 20px;
	width: 85px;
	span {
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 128.571% */
	}
`;
