export const ACCESS_TOKEN = 'access_token';
export const SOUND_CONFIG = 'SOUND_CONFIG';
export const SUPPORTED_PLATFORM = ['android', 'android_x', 'ios'];

export enum NotificationType {
	TELEGRAM_AGE = 'TELEGRAM_AGE',
	INVITE_FRIEND = 'INVITE_FRIEND',
	JOIN_WITH_CODE = 'JOIN_WITH_CODE',
	REFERRAL_COMMISSION = 'REFERRAL_COMMISSION',
	BOOSTER_BONUS = 'BOOSTER_BONUS',
}
