import React from 'react';
import styled from 'styled-components';

const LoadingLayout = () => {
	return (
		<Wrapper>
			<Layer />
			<Loading src="/images/loading.gif?v=1" alt="loading" />
			<LayerBottom />
		</Wrapper>
	);
};

const Layer = styled.div`
	background-image: url('/images/home/bg.png');
	/* background-color: #041720; */
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const LayerBottom = styled.div`
	background-image: url('/images/home/bg-bottom.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100dvh;
	background: #041720;
	position: relative;
	overflow: hidden;
	z-index: 10;
`;

const Loading = styled.img`
	position: absolute;
	top: 30%;
	width: 100%;
	transform: scale(1.5);
`;

export default LoadingLayout;
