import { Button, Flex, Typography } from 'antd';
import styled from 'styled-components';
import { IPackage } from './UpgradeBoosterPopup';
import { formatCurrency } from '../../utils/copy';

interface IProps {
	info: IPackage;
	isUpgraded: boolean;
	onUpgrade: (id: string) => void;
}

export const getPackageImage = (packageName: string) => {
	return `/images/packages/${packageName.toLowerCase().replace(' ', '-')}.svg`;
};

export default function PackageItem({
	info: { id, name, description, price, loyaltyTokenSymbol },
	isUpgraded = false,
	onUpgrade,
}: IProps) {
	return (
		<Wrapper>
			<InfoWrapper>
				<PackageImageWrapper>
					<Image src={getPackageImage(name)} alt={name} />
				</PackageImageWrapper>

				<div>
					<Title>{name}</Title>
					<Description>{description}</Description>
				</div>
			</InfoWrapper>
			<RightBlock>
				<PriceWrapper>
					<TokenImg src="/images/tokens/patc.svg?v=1" alt="patc" />
					<Price>{formatCurrency(parseFloat(price))}</Price>
				</PriceWrapper>

				<UpgradeButton
					onClick={() => {
						onUpgrade(id);
					}}
					disabled={isUpgraded}
				>
					Upgrade
				</UpgradeButton>
			</RightBlock>
		</Wrapper>
	);
}

const RightBlock = styled(Flex)`
	flex-direction: column;
	justify-content: space-between;
	align-self: stretch;
`;
const Wrapper = styled.div`
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	/* width: 100%; */
	gap: 20px;
	border: 1px solid #273662;
	background: #0f2051;
`;
const InfoWrapper = styled.div`
	display: flex;
	/* align-items: center; */
	gap: 10px;
	flex: 1;
`;

const PackageImageWrapper = styled.div`
	display: flex;
	width: 76px;
	height: 76px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #273662;
	background: #000;
	overflow: hidden;
	flex-shrink: 0;
`;

const Image = styled.img`
	height: 63px;
`;
const Title = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
	margin-bottom: 2px;
`;
const Description = styled(Typography)`
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 13px; /* 118.182% */
	margin-bottom: 4px;
`;
const PriceWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;
const TokenImg = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;
const Price = styled(Typography)`
	color: #f5d245;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
const UpgradeButton = styled(Button)`
	padding: 8px;
	height: max-content;
	span {
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 128.571% */
	}
`;
