import { Flex, Typography } from 'antd';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { MISSION_CODE } from '../../constants/mission';
import { MissionRequest } from '../../service/mission.request';
import TaskItemComponent from './TaskItem';
import { IMission } from '../../redux/reducers/mission.slice';
import ReferralRequest from '../../service/referral.request';
import { useEffect, useState } from 'react';

interface IProps {
	list: IMission[];
	reFetchMissions: () => void;
	isGrouped?: boolean;
	updateMission: (mission: IMission) => void;
	partnershipTaskGroup: Record<string, IMission[]>;
	fetchSummary: () => void;
}

export default function TaskList({
	list,
	reFetchMissions,
	isGrouped = false,
	updateMission,
	partnershipTaskGroup,
	fetchSummary,
}: IProps) {
	const referralRequest = new ReferralRequest();
	const [totalInvited, setTotalInvited] = useState(0);
	const [loading, setLoading] = useState(new Set());
	const getTotalInvited = async () => {
		try {
			const res = await referralRequest.countActiveNetwork();
			setTotalInvited(parseInt(res.data.data.totalActive));
		} catch (error) {
			console.log(error);
		}
	};

	const claimReward = async (IMission: IMission) => {
		if (loading.has(IMission.id)) return;
		try {
			setLoading((prev) => new Set(prev).add(IMission.id));
			toast.loading('Claiming...');
			const req = new MissionRequest();
			await req.claim(IMission.id);
			updateMission(IMission);
			fetchSummary();
			toast.dismiss();
			toast.success('Claim successful');
		} catch (error: any) {
			console.log(error);
			const errStatus = error?.response?.status;
			if (
				(IMission.code === MISSION_CODE.JOIN_TELE_CHANNEL ||
					IMission.code === MISSION_CODE.JOIN_TELE_COMMUNITY) &&
				errStatus &&
				errStatus === 400
			) {
				reFetchMissions();
			}
			toast.dismiss();
			const message = error?.response?.data?.message || '';
			toast.error(message);
		} finally {
			setLoading((prev) => {
				const newSet = new Set(prev);
				newSet.delete(IMission.id);
				return newSet;
			});
		}
	};

	const startMission = async (missionId: string) => {
		if (loading.has(missionId)) return;
		try {
			setLoading((prev) => new Set(prev).add(missionId));
			const req = new MissionRequest();
			const res = await req.start(missionId);
			if (res.data?.data) {
				reFetchMissions();
			}
			const mission = list.find((item) => item.id === missionId);
			if (mission && mission.info.url) {
				const tg = window.Telegram.WebApp;
				if (
					(
						[
							MISSION_CODE.JOIN_TELE_CHANNEL,
							MISSION_CODE.JOIN_TELE_COMMUNITY,
						] as string[]
					)?.includes(mission.code)
				) {
					tg?.openTelegramLink(mission.info?.url);
				} else {
					tg?.openLink(mission.info?.url);
				}
			}
		} catch (error: any) {
			console.log(error);
			const message = error?.response?.data?.message || '';
			toast.error(message);
		} finally {
			setLoading((prev) => {
				const newSet = new Set(prev);
				newSet.delete(missionId);
				return newSet;
			});
		}
	};

	useEffect(() => {
		getTotalInvited();
	}, []);

	return (
		<Wrapper>
			<CardsWrapper>
				{isGrouped ? (
					<>
						{Object.keys(partnershipTaskGroup).map((groupName) => (
							<Flex
								style={{
									flexDirection: 'column',
									gap: '12px',
								}}
								key={groupName}
							>
								<Title>{groupName}</Title>
								{partnershipTaskGroup[groupName].map((task) => (
									<TaskItemComponent
										key={task.id}
										task={task}
										claimReward={claimReward}
										startMission={startMission}
										totalInvited={totalInvited}
										loading={loading.has(task.id)}
									/>
								))}
							</Flex>
						))}
					</>
				) : (
					list.map((task) => (
						<TaskItemComponent
							key={task.id}
							task={task}
							claimReward={claimReward}
							startMission={startMission}
							totalInvited={totalInvited}
							loading={loading.has(task.id)}
						/>
					))
				)}
			</CardsWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const Title = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
