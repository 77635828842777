import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../components/UI/Header';
import ListToken from '../components/Reward/ListToken';

export default function MyRewardsPage() {
	const navigate = useNavigate();
	return (
		<>
			<Wrapper>
				<Header
					onBack={() => {
						navigate(-1);
					}}
					title="Reward"
				/>
				<ListWrapper>
					<ListToken />
				</ListWrapper>
			</Wrapper>
		</>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 0;
	box-sizing: border-box;
`;
const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 30px 16px;
	margin-top: 20px;
	color: #fff;
`;
