import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DailyTask from '../components/Mission/DailyTask';
import MissionHeader from '../components/Mission/Header';
import MissionSummary from '../components/Mission/Summary';
import TaskList from '../components/Mission/TaskList';
import { MISSION_STATUS } from '../constants/mission';
import { MissionRequest } from '../service/mission.request';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { fetchMissions, IMission } from '../redux/reducers/mission.slice';
enum TAB {
	DAILY = 'DAILY',
	PATC = 'PATC',
	PARTNERSHIP = 'PARTNER',
}

const TABS = [
	{
		label: 'Daily',
		value: TAB.DAILY,
	},
	{
		label: 'PATC',
		value: TAB.PATC,
	},
	{
		label: 'Partners',
		value: TAB.PARTNERSHIP,
	},
];
export interface ISummary {
	completedMission: number;
	loyaltyReceived: string;
	loyaltySymbol: string;
}

export default function MissionPage() {
	const { missions } = useAppSelector((state: RootState) => state.mission);
	const [summary, setSummary] = useState<ISummary | null>(null);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const user = useAppSelector((state: RootState) => state.user);
	const [selectedTab, setSelectedTab] = useState<TAB>(TAB.DAILY);

	const [patcTasks, setPatcTasks] = useState<IMission[]>([]);
	const [parnershipTasks, setPartnershipTasks] = useState<IMission[]>([]);
	const dailyTasks = missions.filter((mission: IMission) =>
		mission.missionTag?.includes(TAB.DAILY)
	);

	const partnershipTasks = missions
		.filter((mission: IMission) =>
			mission.missionTag?.includes(TAB.PARTNERSHIP)
		)
		.sort((missionA, missionB) => missionA.priority - missionB.priority);

	const partnershipTaskGroup = parnershipTasks.reduce((acc, mission) => {
		if (mission.missionGroupName) {
			if (acc[mission.missionGroupName]) {
				acc[mission.missionGroupName].push(mission);
			} else {
				acc[mission.missionGroupName] = [mission];
			}
		}
		return acc;
	}, {} as Record<string, IMission[]>);

	useEffect(() => {
		arrangePatcTasks(missions);
		arrangePartnershipTasks(missions);
	}, [missions]);

	const arrangePatcTasks = (missions: IMission[]) => {
		const sortedPatcTask = missions
			.filter((mission: IMission) => mission.missionTag?.includes(TAB.PATC))
			.sort((missionA, missionB) => missionA.priority - missionB.priority);
		const incompletedList = sortedPatcTask.filter(
			(task) => task.status !== MISSION_STATUS.SUCCESS
		);
		const completedList = sortedPatcTask.filter(
			(task) => task.status == MISSION_STATUS.SUCCESS
		);
		setPatcTasks([...incompletedList, ...completedList]);
	};

	const arrangePartnershipTasks = (missions: IMission[]) => {
		const sortedPartnershipTask = missions
			.filter((mission: IMission) =>
				mission.missionTag?.includes(TAB.PARTNERSHIP)
			)
			.sort((missionA, missionB) => missionA.priority - missionB.priority);
		const incompletedList = sortedPartnershipTask.filter(
			(task) => task.status !== MISSION_STATUS.SUCCESS
		);
		const completedList = sortedPartnershipTask.filter(
			(task) => task.status == MISSION_STATUS.SUCCESS
		);
		setPartnershipTasks([...incompletedList, ...completedList]);
	};

	const updatePatcTaskList = (mission: IMission) => {
		const missionIndex = patcTasks.findIndex((m) => m.id === mission.id);
		console.log('missionIndex', missionIndex, mission);
		if (missionIndex < 0) return;

		const newTasks = [...patcTasks];
		newTasks.splice(missionIndex, 1, {
			...mission,
			status: MISSION_STATUS.SUCCESS,
		});
		setPatcTasks(newTasks);
	};

	const updateParnershipTaskList = (mission: IMission) => {
		const missionIndex = parnershipTasks.findIndex((m) => m.id === mission.id);
		if (missionIndex < 0) return;
		const newTasks = [...parnershipTasks];
		newTasks.splice(missionIndex, 1, {
			...mission,
			status: MISSION_STATUS.SUCCESS,
		});
		setPartnershipTasks(newTasks);
	};
	const fetchSummary = async () => {
		try {
			setLoading(true);
			const req = new MissionRequest();
			const res = await req.getSummary();
			if (res.data?.data) {
				setSummary(res.data?.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const reFetchMissions = async () => {
		await fetchSummary();
		await dispatch(fetchMissions());
	};

	useEffect(() => {
		if (!user?.id) return;
		dispatch(fetchMissions());
		fetchSummary();
	}, [user?.id]);

	const isDisplayThunderIcon = (tab: TAB) => {
		switch (tab) {
			case TAB.DAILY:
				return (
					dailyTasks.filter((task) => task.status !== MISSION_STATUS.SUCCESS)
						.length > 0
				);
			case TAB.PATC:
				return (
					patcTasks.filter((task) => task.status !== MISSION_STATUS.SUCCESS)
						.length > 0
				);
			case TAB.PARTNERSHIP:
				return (
					partnershipTasks.filter(
						(task) => task.status !== MISSION_STATUS.SUCCESS
					).length > 0
				);
			default:
				return false;
		}
	};

	return (
		<Wrapper>
			<MissionHeader />
			<MissionSummary summary={summary} loading={loading} />
			<Tabs id="mission">
				{TABS.map((tab) => (
					<Tab
						key={tab.value}
						style={{
							border:
								selectedTab === tab.value
									? '1px solid #A7D9FC'
									: '1px solid #273662',
							backgroundColor:
								selectedTab === tab.value
									? '#0033AD'
									: 'rgba(172, 172, 172, 0.05)',
							color: selectedTab === tab.value ? '#fff' : '#B4B4B4',
						}}
						onClick={() => setSelectedTab(tab.value)}
					>
						{tab.label}{' '}
						{isDisplayThunderIcon(tab.value) && (
							<ThunderIcon src="/images/thunder.gif" alt="thunder" />
						)}
					</Tab>
				))}
			</Tabs>
			{selectedTab == TAB.DAILY && (
				<DailyTask list={dailyTasks} reFetchMissions={reFetchMissions} />
			)}
			{selectedTab == TAB.PATC && (
				<TaskList
					partnershipTaskGroup={{}}
					list={patcTasks}
					reFetchMissions={reFetchMissions}
					updateMission={updatePatcTaskList}
					fetchSummary={fetchSummary}
				/>
			)}
			{selectedTab == TAB.PARTNERSHIP && (
				<TaskList
					list={partnershipTasks}
					isGrouped
					reFetchMissions={reFetchMissions}
					updateMission={updateParnershipTaskList}
					partnershipTaskGroup={partnershipTaskGroup}
					fetchSummary={fetchSummary}
				/>
			)}
		</Wrapper>
	);
}

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	padding-bottom: 110px;
`;

const Tabs = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 30px;
	gap: 8px;
	width: 100%;
	overflow-x: auto;
`;

const Tab = styled.div`
	padding: 8px 20px;
	border-radius: 8px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	box-sizing: border-box;
	height: fit-content;
`;

const ThunderIcon = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;
