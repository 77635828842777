import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PackageItem from '../components/Packages/Item';
import UpgradeBoosterPopup, {
	IPackage,
} from '../components/Packages/UpgradeBoosterPopup';
import { fetchMyBoosters } from '../redux/reducers/booster.slice';
import Header from '../components/UI/Header';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { BoosterRequest } from '../service/booster.request';

export default function PackagesPage() {
	const navigate = useNavigate();
	const [packageList, setPackageList] = useState<IPackage[]>([]);
	const [packageSelected, setPackageSelected] = useState<string | null>(null);
	const { myBoosters } = useAppSelector((state: RootState) => state.booster);
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);

	const fetchPackages = async () => {
		try {
			setLoading(true);
			const boosterReq = new BoosterRequest();
			const res = await boosterReq.getPackages();
			if (res.data?.data) {
				setPackageList(res.data?.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectPackage = async (packageItem: IPackage) => {
		if (new BigNumber(packageItem.price).gt(balance)) {
			toast.error('Not enough balance');
			return;
		}
		setPackageSelected(packageItem.id);
	};

	const closeUpgradePopup = () => {
		setPackageSelected(null);
	};

	useEffect(() => {
		fetchPackages();
		if (!myBoosters?.length) {
			dispatch(fetchMyBoosters());
		}
	}, []);

	const packageSelectedInfo = packageList.find(
		(packageItem) => packageItem.id === packageSelected
	);

	return (
		<>
			<Wrapper>
				<Header
					onBack={() => {
						navigate(-1);
					}}
					title="Packages"
				/>
				<ListWrapper>
					{loading
						? new Array(5).fill(0).map((_, index: number) => (
								<PackagePlaceholder key={index}>
									<PackageImageWrapper />{' '}
								</PackagePlaceholder>
						  ))
						: packageList
								.sort(
									(prevPackage, nextPackage) =>
										Number(prevPackage.price) - Number(nextPackage.price)
								)
								.map((packageItem) => (
									<PackageItem
										key={packageItem.id}
										info={packageItem}
										onUpgrade={(id) => {
											handleSelectPackage(packageItem);
										}}
										isUpgraded={
											!!myBoosters.filter(
												(myBooster) => myBooster.packageId === packageItem.id
											)?.length
										}
									/>
								))}
				</ListWrapper>
			</Wrapper>

			<UpgradeBoosterPopup
				open={!!packageSelectedInfo}
				packageInfo={packageSelectedInfo}
				onClose={closeUpgradePopup}
			/>
		</>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%; */
	padding: 0;
	box-sizing: border-box;
`;
const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 30px 16px;
	margin-top: 40px;
`;
const PackagePlaceholder = styled.div`
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 14px;
	/* width: 100%; */
	gap: 20px;
	border: 1px solid #273662;
	background: #0f2051;
	height: 104px;
	width: 100%;
	box-sizing: border-box;
`;
const PackageImageWrapper = styled.div`
	display: flex;
	width: 76px;
	height: 76px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #273662;
	background: #000;
	overflow: hidden;
	flex-shrink: 0;
`;
