import React, { useEffect, useState } from 'react';
import {
	Balance,
	BalanceTitle,
	BalanceToken,
	BalanceValue,
	ConfirmButton,
	ContainerPopup,
	Item,
	Title,
	ViewToken,
	ViewTokenContent,
	ViewTokenImg,
	ViewTokenName,
	ViewTokenSymbol,
	WarningText,
	Withdraw,
	WithdrawButton,
	WithdrawChoose,
	WithdrawChooseButton,
	WithdrawChooseToken,
	WithdrawLabel,
	WithdrawValue,
	Wrapper,
} from './ListTokent.styled';
import PopupUI from '../../UI/Popup/PopupUI';
import RadioCheckedIc from '../../Icons/RadioCheckedIc';
import CircleIc from '../../Icons/CircleIc';
import {
	RootState,
	useAppDispatch,
	useAppSelector,
} from '../../../redux/store';
import { getWalletReq } from '../../../redux/actions/wallet.action';
import walletRequest from '../../../service/wallet.request';
import { useTonAddress } from '@tonconnect/ui-react';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '../../../utils/copy';

interface IToken {
	tokenUrl: string;
	symbol: string;
	name: string;
	balance: string;
	coinLimit: number;
}

const coinLimits = [
	{
		token: 'BTC',
		value: 0.0002,
	},
	{
		token: 'ETH',
		value: 0.004,
	},
	{
		token: 'USDT',
		value: 10,
	},
	{
		token: 'BNB',
		value: 0.02,
	},
	{
		token: 'SOL',
		value: 0.07,
	},
	{
		token: 'USDC',
		value: 10,
	},
	{
		token: 'PATC',
		value: 0,
	},
	{
		token: 'NOT',
		value: 1300,
	},
	{
		token: 'TON',
		value: 2,
	},
	{
		token: 'SUI',
		value: 7,
	},
];

const ListToken = () => {
	const user = useAppSelector((state: RootState) => state.user);
	const [open, setOpen] = useState(false);
	const [tokenSelected, setTokenSelected] = useState<IToken>();
	const dispatch = useAppDispatch();
	const wallets = useAppSelector((state) => state.wallet.wallets);
	const isLoading = useAppSelector((state) => state.wallet.isLoadingWallet);
	const currencies = useAppSelector((state) => state.wallet.currencies);
	const [dataSources, setDataSources] = useState<IToken[]>([]);
	const [withdrawSelected, setWithdrawSelected] = useState<number>();
	const userFriendlyAddress = useTonAddress();

	const balancePatc = useAppSelector((state) => state.loyaltyWallet.balance);

	const onClose = () => {
		setOpen(false);
	};

	const onClickWithdraw = (item: IToken) => {
		setWithdrawSelected(undefined);
		setTokenSelected(item);
		setOpen(true);
	};

	const handleWithdraw = async (token: IToken) => {
		const body = {
			walletAddress: userFriendlyAddress,
			symbol: token.symbol,
			amount: `${
				withdrawSelected ? token.coinLimit * withdrawSelected : token.coinLimit
			}`,
			memo: '',
		};
		try {
			const res = await walletRequest.withdraw(body);
			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (wallets.length) {
			const convertData = wallets.map((wallet) => {
				const currency = currencies.find((cur) => cur.symbol === wallet.symbol);
				const limit = coinLimits.find((coin) => coin.token === wallet.symbol);
				return {
					tokenUrl: currency?.logo || '',
					symbol: wallet.symbol,
					name: wallet.symbol,
					balance: currency?.showDecimals
						? new BigNumber(wallet.totalBalance).toFixed(currency?.showDecimals)
						: new BigNumber(wallet.totalBalance).toString(),
					coinLimit: limit?.value || 0,
				};
			});
			setDataSources(
				[
					{
						tokenUrl: '/images/wallet/patc.svg?v=1',
						symbol: 'PATC',
						name: 'PadCoin',
						balance: balancePatc,
						coinLimit: 0,
					},
					...convertData,
				]?.filter((wallet) => {
					const value = new BigNumber(wallet.balance);
					const zero = new BigNumber(0);
					if (value.isGreaterThan(zero)) {
						return true;
					} else {
						return false;
					}
				})
			);
		}
	}, [wallets, balancePatc]);

	useEffect(() => {
		if (!user?.id) return;
		dispatch(getWalletReq());
	}, [user?.id]);

	return (
		<>
			<Wrapper>
				<Title>Tokens</Title>
				{isLoading
					? new Array(5)
							.fill(0)
							.map((_, index) => (
								<Item key={index} style={{ height: '60px' }} />
							))
					: dataSources.map((item) => (
							<Item key={item.symbol}>
								<ViewToken>
									<ViewTokenContent>
										<ViewTokenImg src={item.tokenUrl} alt={item.name} />
										<div>
											<ViewTokenSymbol>{item.symbol}</ViewTokenSymbol>
										</div>
									</ViewTokenContent>
								</ViewToken>

								<Withdraw>
									<WithdrawValue>
										{formatCurrency(new BigNumber(item.balance).toNumber(), 10)}
									</WithdrawValue>
									<WithdrawButton
										isDisable={item.symbol === 'PATC'}
										onClick={() => {
											if (item.symbol !== 'PATC') onClickWithdraw(item);
										}}
									>
										Withdraw
									</WithdrawButton>
								</Withdraw>
							</Item>
					  ))}
			</Wrapper>
			{tokenSelected && (
				<PopupUI
					title="Withdraw"
					open={open}
					onClose={onClose}
					footer={
						<ConfirmButton
							// onClick={() => handleWithdraw(tokenSelected)}
							// isDisable={balance < tokenSelected.coinLimit}
							isDisable={true}
						>
							Confirm
						</ConfirmButton>
					}
				>
					<ContainerPopup>
						<Balance>
							<BalanceToken
								src={tokenSelected.tokenUrl}
								alt={tokenSelected.symbol}
							/>

							<div>
								<BalanceTitle>Balance</BalanceTitle>
								<BalanceValue>
									{formatCurrency(
										new BigNumber(tokenSelected.balance).toNumber(),
										10
									)}{' '}
									{tokenSelected.symbol}
								</BalanceValue>
							</div>
						</Balance>

						<WithdrawLabel>Choose a withdraw amount:</WithdrawLabel>

						<WithdrawChoose>
							<WithdrawChooseButton
								isActive={withdrawSelected === 1}
								isDisable={new BigNumber(tokenSelected.balance).isLessThan(
									tokenSelected.coinLimit
								)}
								onClick={() => {
									if (
										!new BigNumber(tokenSelected.balance).isLessThan(
											tokenSelected.coinLimit
										)
									) {
										setWithdrawSelected(1);
									}
								}}
							>
								{withdrawSelected === 1 ? <RadioCheckedIc /> : <CircleIc />}
								<span>{formatCurrency(tokenSelected.coinLimit)}</span>
								<WithdrawChooseToken
									src={tokenSelected.tokenUrl}
									alt={tokenSelected.name}
								/>
							</WithdrawChooseButton>
							<WithdrawChooseButton
								isActive={withdrawSelected === 2}
								isDisable={new BigNumber(tokenSelected.balance).isLessThan(
									tokenSelected.coinLimit * 2
								)}
								onClick={() => {
									if (
										!new BigNumber(tokenSelected.balance).isLessThan(
											tokenSelected.coinLimit * 2
										)
									) {
										setWithdrawSelected(2);
									}
								}}
							>
								{withdrawSelected === 2 ? <RadioCheckedIc /> : <CircleIc />}
								<span>
									{tokenSelected.coinLimit &&
										formatCurrency(tokenSelected.coinLimit * 2)}
								</span>
								<WithdrawChooseToken
									src={tokenSelected.tokenUrl}
									alt={tokenSelected.name}
								/>
							</WithdrawChooseButton>
						</WithdrawChoose>

						{new BigNumber(tokenSelected.balance).isLessThan(
							tokenSelected.coinLimit
						) && (
							<WarningText>
								Insufficient balance to withdraw. Please accumulate more{' '}
								{tokenSelected.symbol} to meet the withdrawal requirement
							</WarningText>
						)}
					</ContainerPopup>
				</PopupUI>
			)}
		</>
	);
};

export default ListToken;
